import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { getActualAligners } from '../../../../utils/aligners.utils';

const styles = (theme) => ({
  root: {
    textAlign: 'left',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  center: {
    textAlign: 'center'
  }
});

const TreatmentInformations = ({ patient: { aligners }, patient, classes }) => {
  const { t } = useTranslation(['patients', 'common']);
  const actualAligners = getActualAligners(aligners);

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {t('patients:patients.view.treatmentInformations.title')}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Typography color="textSecondary">
            {t('patients:patients.view.treatmentInformations.start')}
          </Typography>
          <Typography gutterBottom>
            {t('common:common.date.detailed', {
              date: parseISO(patient.treatment_started_at)
            })}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Typography color="textSecondary">
            {t('patients:patients.view.treatmentInformations.numberAligners')}
          </Typography>
          <Typography gutterBottom>{patient.nb_aligners}</Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Typography color="textSecondary">
            {t('patients:patients.view.treatmentInformations.progression')}
          </Typography>
          <Typography gutterBottom>
            {actualAligners.serie} / {patient.nb_aligners} (
            {Math.round((actualAligners.serie * 100) / patient.nb_aligners)}
            %)
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(TreatmentInformations);
