import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import TextField from '../../../final-form/TextField';
import { styles } from '../styles';

const Booking = ({ classes }) => {
  const { t } = useTranslation('patients');

  return (
    <Paper variant="outlined" className={classes.block}>
      <Typography className={classes.subHeader}>
        {t('patients:patients.create.subHeaders.booking')}
      </Typography>

      <Box className={classes.row}>
        <Field
          name="booking.date"
          label={t('patients:patients.create.booking.date')}
          component={TextField}
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
        <Field
          name="booking.time"
          label={t('patients:patients.create.booking.time')}
          component={TextField}
          type="time"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Box>
    </Paper>
  );
};

export default withStyles(styles)(Booking);
