import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { withStyles } from '@material-ui/core/styles';
import Header from '../Header';

import { v2 } from '../../axios';

import Loadable from '../Loadable';
import Charge from './Charge';
import Report from './Report';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  header: {
    padding: theme.spacing(),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 700
  }
});

class BookingsList extends Component {
  state = {
    charges: [],
    error: null,
    isFetching: false
  };

  async componentDidMount() {
    try {
      this.fetchCharges();
    } catch (error) {
      this.setState({
        error: 'Erreur lors de la récuperation des éléments.',
        isFetching: false
      });
    }
  }

  fetchCharges = async () => {
    this.setState({ isFetching: true });

    const { data: charges } = await v2.get('/charges');

    this.setState({ charges, isFetching: false });
  };

  render() {
    const { classes } = this.props;
    const { charges, isFetching } = this.state;

    return (
      <>
        <Report />

        <Paper>
          <Header
            breadcrumbs={[
              { name: 'Suivi des versements', url: '/facturation' }
            ]}
          />
          <Loadable loading={isFetching}>
            <div className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Patient</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Prix</TableCell>
                    <TableCell>Montant net</TableCell>
                    <TableCell>Commission plateforme</TableCell>
                    <TableCell>Factures</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {charges.length ? (
                    charges.map((charge) => {
                      return <Charge charge={charge} key={charge.id} />;
                    })
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Aucun élément
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </Loadable>
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(BookingsList);
