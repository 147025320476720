import occlussalUpper from './assets/images/occlussal-upper.png';
import occlussalLower from './assets/images/occlussal-lower.png';
import buccalLeft from './assets/images/buccal-left.png';
import buccalRight from './assets/images/buccal-right.png';
import labialAnterior from './assets/images/labial-anterior.png';
import frontalRepose from './assets/images/frontal-repose.png';
import frontalSmiling from './assets/images/frontal-smiling.png';
import profileRepose from './assets/images/profile-repose.png';

const angles = [
  {
    name: 'frontal_repose',
    background: frontalRepose,
    onlyCheckUp: true
  },
  {
    name: 'frontal_smiling',
    background: frontalSmiling,
    onlyCheckUp: true
  },
  {
    name: 'profile_repose',
    background: profileRepose,
    onlyCheckUp: true
  },
  {
    name: 'labial_anterior',
    background: labialAnterior
  },
  {
    name: 'occlussal_upper',
    background: occlussalUpper
  },
  {
    name: 'occlussal_lower',
    background: occlussalLower
  },
  {
    name: 'buccal_left',
    background: buccalLeft
  },
  {
    name: 'buccal_right',
    background: buccalRight
  }
];

export default angles;
