import React, { useContext, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Bookings from './Bookings';
import TreatmentPlans from './TreatmentPlans';
import Availabilities from './Availabilities';
import Reviews from './Reviews';
import DialogCreateBookingRequest from '../bookingRequests/DialogCreateBookingRequest';
import { UserContext } from '../../store/User';
// import Income from "./Income";
// import IncomeChart from "./IncomeChart";

const Dashboard = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation('dashboard');

  const [createdBookingRequest, setCreatedBookingRequest] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  if (createdBookingRequest) {
    return <Redirect to="/reservations" />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <Typography variant="h6">Dashboard</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        {user.canAccessBookingRequests && (
          <Box display="flex" justifyContent={matches ? 'flex-end' : 'center'}>
            <DialogCreateBookingRequest
              type="button"
              onSuccess={() => {
                setCreatedBookingRequest(true);
              }}
            />
          </Box>
        )}
      </Grid>

      {user.canAccessBookings && <Bookings />}
      {user.canAccessBookings && <Availabilities />}
      {user.canAccessTreatments && <TreatmentPlans />}
      {user.canMonitor && <Reviews />}

      <Grid item xs={12}>
        <Typography variant="h6" align="right">
          {t('dashboard:dashboard.assistance', {
            number: '+33 7 57 59 67 68'
          })}
        </Typography>
      </Grid>

      {/* <Income />
    <IncomeChart endpoint="/income-month" title="Revenu - 30 derniers jours"/>
    <IncomeChart endpoint="/income-year" title="Revenu - 12 derniers mois"/> */}
    </Grid>
  );
};

export default Dashboard;
