import { format, set } from 'date-fns';
import i18n from '../i18n';

export const formatCurrency = new Intl.NumberFormat(i18n.language, {
  style: 'currency',
  currency: 'EUR'
}).format;

export const getHourFromTime = (time) => {
  const [hour] = time.split(':');

  return parseInt(hour, 10);
};

export const getMinuteFromTime = (time) => {
  const [, minute] = time.split(':');

  return parseInt(minute, 10);
};

export const setTimeOfDate = (date, time) => {
  return set(date, {
    hours: getHourFromTime(time),
    minutes: getMinuteFromTime(time)
  });
};

export const formatDateTime = (date) => {
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};
