import React, { useCallback, useState } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { api } from '../../../../../axios';

const AlignerRemarks = ({ remarks }) => {
  const { t } = useTranslation(['patients', 'common']);
  const [translation, setTranslation] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);

  const translate = useCallback(
    async (e) => {
      e.preventDefault();
      setIsTranslating(true);
      try {
        const { data: translatedText } = await api.post('/translate', {
          text: remarks
        });

        setTranslation(translatedText);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setIsTranslating(false);
      }
    },
    [remarks]
  );

  if (!remarks) {
    return null;
  }

  return (
    <Box mb={2}>
      <Typography color="textSecondary" gutterBottom>
        {t('patients:patients.viewAligner.remarks')}
      </Typography>
      <Typography variant="subtitle1">{remarks}</Typography>
      {translation ? (
        <Typography color="textSecondary">{translation}</Typography>
      ) : (
        <Typography variant="caption">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link href="#" onClick={translate}>
            {isTranslating
              ? t('common:common.translate.translating')
              : t('common:common.translate.cta')}
          </Link>
        </Typography>
      )}
    </Box>
  );
};

export default AlignerRemarks;
