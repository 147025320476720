import React, { useEffect, useState, useCallback, useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { Form as FinalForm, Field } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { Box } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Upload from '../../Upload';

import angles from '../../../angles';
import { v2 } from '../../../axios';
import DialogWithButton from '../../DialogWithButton';

import PatientInformations from './PatientInformations';
import Informations from './BookingInformations';
import DigitalImpressions from './DigitalImpressions';
import Questions from './Questions';
import { ReScheduleBooking } from './ReScheduleBooking/ReScheduleBooking';

import Checkbox from '../../final-form/Checkbox';
import TextField from '../../final-form/TextField';

import PhotosUpload from '../../PhotosUpload';

import AsyncActionButton from '../../AsyncActionButton';
import Comments from './comments/Comments';
import DownloadLabReport from '../../labReport/DownloadLabReport';

import {
  getInitialValues,
  redirectToImpressionDocument
} from '../bookings.utils';
import { download } from '../../../utils/files.utils';
import { SnackbarContext } from '../../Snackbar';

import { UserContext } from '../../../store/User';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
});

const Booking = ({ classes }) => {
  const match = useRouteMatch();
  const { t } = useTranslation('bookings');
  const [booking, setBooking] = useState(null);

  const snackbar = useContext(SnackbarContext);
  const user = useContext(UserContext);
  const history = useHistory();

  const setAsNotHonored = async () => {
    try {
      const { data } = await v2.post(`bookings/${booking.id}/not-honored`);
      snackbar.open(
        t('bookings:bookings.viewBooking.notHonored.snackbar.success'),
        'success'
      );
      setBooking(data);
      history.push('/consultations');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchBooking = useCallback(async () => {
    const { bookingId } = match.params;

    try {
      const { data } = await v2.get(`/bookings/${bookingId}`);
      setBooking(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [match.params]);

  useEffect(() => {
    fetchBooking();
  }, [fetchBooking]);

  const validate = (values) => {
    const errors = {};

    if (
      !(booking.type === 'pre_treatment' || booking.type === 'end_treatment')
    ) {
      return errors;
    }

    if (values.decision_practician) {
      angles.forEach((angle) => {
        if (!values[angle.name]) {
          if (
            !angle.onlyCheckUp ||
            (angle.onlyCheckUp && booking.type === 'pre_treatment')
          ) {
            errors[angle.name] = 'Obligatoire';
          }
        }
      });

      if (values.is_digital_impression) {
        if (!values.digital_impression_upper) {
          errors.digital_impression_upper = 'Obligatoire';
        }
        if (
          !values.digital_impression_lower &&
          !values.is_allowed_to_upload_one_scan
        ) {
          errors.digital_impression_lower = 'Obligatoire';
        }
      }

      if (values.has_xray && !values.xray) {
        errors.xray = 'Obligatoire';
      }

      if (
        booking.type === 'pre_treatment' &&
        values.premolars &&
        !values.premolars_details
      ) {
        errors.premolars_details = 'Obligatoire';
      }
    } else if (booking.type === 'pre_treatment' && !values.reason_for_refusal) {
      errors.reason_for_refusal = 'Obligatoire';
    }

    return errors;
  };

  const submitBooking = async (values) => {
    const data = {
      ...values,
      is_digital_impression: values.is_digital_impression ? 1 : 0,
      has_xray: values.has_xray ? 1 : 0,
      requested_xray: values.requested_xray ? 1 : 0,
      type: booking.type,

      decision_practician: values.decision_practician ? 1 : 0,
      premolars: values.premolars ? 'yes' : 'no',
      whitening: values.whitening ? 'yes' : 'no',
      descaling: values.descaling ? 'yes' : 'no',
      decay: values.decay ? 'yes' : 'no',
      fillings: values.fillings ? 'yes' : 'no',
      loose_teeth: values.loose_teeth ? 'yes' : 'no',
      roots: values.roots ? 'yes' : 'no',
      non_erupted: values.non_erupted ? 'yes' : 'no',
      gum: values.gum ? 'yes' : 'no',
      tmj: values.tmj ? 'yes' : 'no',
      cancer: values.cancer ? 'yes' : 'no'
    };

    try {
      await v2.post(`bookings/${booking.id}/confirm`, data);
      await fetchBooking();
      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      const errors = {};

      return errors;
    }
  };

  const generateInformationsDocument = async () => {
    const { data } = await v2.post('/impressions', {
      patient_id: booking.patient.id
    });

    return redirectToImpressionDocument(data, user);
  };

  const generateQuotation = async () => {
    const {
      user: { firstname, lastname }
    } = booking.patient;
    const fileName = `devis-${firstname.toLowerCase()}-${lastname.toLowerCase()}.pdf`;
    return download({
      endpoint: `/bookings/${booking.id}/generate-quotation`,
      fileName
    });
  };

  if (!booking) {
    return null;
  }

  const handleReschedule = (newDate) => {
    setBooking({ ...booking, date: newDate });
  };

  const { patient, status } = booking;
  const initialValues = getInitialValues({ patient, booking });
  const disabled = status === 'done';

  return (
    <FinalForm
      onSubmit={submitBooking}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, values, form, hasValidationErrors }) => {
        const isEligible = !!values.decision_practician;

        const handleDecisionClick = () => {
          form.change('decision_practician', !isEligible);
        };

        return (
          <form onSubmit={handleSubmit}>
            <Paper className={classes.root}>
              <PatientInformations patient={patient} />
            </Paper>
            <Paper className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="subtitle1" color="primary">
                    {t('bookings:bookings.viewBooking.details.title')}
                  </Typography>
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Informations booking={booking} />
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      {booking.status !== 'not_honored' && (
                        <Box mb={1}>
                          <DialogWithButton
                            variant="outlined"
                            color="secondary"
                            buttonText={t(
                              'bookings:bookings.viewBooking.notHonored.dialog.title'
                            )}
                            description={t(
                              'bookings:bookings.viewBooking.notHonored.dialog.description'
                            )}
                            onAccept={setAsNotHonored}
                            buttonProps={{
                              fullWidth: true
                            }}
                          />
                        </Box>
                      )}
                      <Box mb={1}>
                        <ReScheduleBooking
                          bookingId={booking?.id}
                          initialDate={parseISO(booking?.date)}
                          onSave={handleReschedule}
                        />
                        <AsyncActionButton
                          onClick={generateInformationsDocument}
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          {t(
                            'bookings:bookings.viewBooking.documents.generateInformation'
                          )}
                        </AsyncActionButton>
                      </Box>
                      <AsyncActionButton
                        onClick={generateQuotation}
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        {t(
                          'bookings:bookings.viewBooking.documents.generateQuotation'
                        )}
                      </AsyncActionButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            {isEligible && (
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  {booking.type === 'mid_treatment' && (
                    <Grid item xs={12}>
                      <DownloadLabReport patientId={booking.patient_id} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="primary">
                      {t('bookings:bookings.viewBooking.photos.title')}
                    </Typography>
                    <PhotosUpload
                      readOnly={disabled}
                      patient={patient}
                      resourceType="booking"
                      resourceId={booking.id}
                      serie={
                        booking.type === 'pre_treatment'
                          ? 'check_up'
                          : 'end_treatment'
                      }
                      onSuccess={(file) => {
                        form.change(file.meta.angle, true);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="primary">
                      {t('bookings:bookings.viewBooking.scan.title')}
                    </Typography>

                    <Field
                      type="checkbox"
                      name="is_digital_impression"
                      disabled={disabled}
                      label={t('bookings:bookings.viewBooking.scan.isDigital')}
                      component={Checkbox}
                    />
                    {!!values.is_digital_impression && (
                      <DigitalImpressions
                        booking={booking}
                        onSuccess={form.change}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="primary">
                      {t('bookings:bookings.viewBooking.xray.title')}
                    </Typography>

                    <Field
                      type="checkbox"
                      name="has_xray"
                      disabled={disabled}
                      label={t('bookings:bookings.viewBooking.xray.hasXray')}
                      component={Checkbox}
                    />
                    {!!values.has_xray && (
                      <Grid item xs={12} sm={6}>
                        <Upload
                          description={t(
                            'bookings:bookings.viewBooking.xray.label'
                          )}
                          receiverId={booking.patient.id}
                          receiverType="patient"
                          resourceId={booking.id}
                          resourceType="booking"
                          type="treatment_xray"
                          fileName={`scan-${booking.patient.reference}`}
                          retryable
                          autoUpload
                          onSuccess={() => {
                            form.change('xray', true);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            )}
            <Paper className={classes.root}>
              <Grid container spacing={2}>
                {booking.type === 'pre_treatment' && (
                  <>
                    {!values.has_xray && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" color="primary">
                          {t(
                            'bookings:bookings.viewBooking.xray.prescription.title'
                          )}
                        </Typography>

                        <Field
                          type="checkbox"
                          name="requested_xray"
                          disabled
                          label={t(
                            'bookings:bookings.viewBooking.xray.prescription.label'
                          )}
                          component={Checkbox}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" color="primary">
                        {t('bookings:bookings.viewBooking.retainers.title')}
                      </Typography>
                      <Field
                        type="checkbox"
                        name="lower_retainer"
                        disabled={disabled}
                        label={t(
                          'bookings:bookings.viewBooking.retainers.lower'
                        )}
                        component={Checkbox}
                      />
                      <Field
                        type="checkbox"
                        name="upper_retainer"
                        disabled={disabled}
                        label={t(
                          'bookings:bookings.viewBooking.retainers.upper'
                        )}
                        component={Checkbox}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" color="primary">
                        {t('bookings:bookings.viewBooking.questions.title')}
                      </Typography>

                      <Questions values={values} booking={booking} />
                    </Grid>
                  </>
                )}
                {!!values.decision_practician && (
                  <Grid item xs={12}>
                    <Comments
                      patientId={booking.patient_id}
                      practicianId={booking.practician_id}
                      disabled={booking.status === 'done'}
                    />
                  </Grid>
                )}
                {!isEligible && (
                  <Grid item xs={12}>
                    <Field
                      disabled={disabled}
                      name="reason_for_refusal"
                      label={t('bookings:bookings.viewBooking.refusal.reason')}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      multiline
                    />
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                {!!booking.validated_at && (
                  <Grid item xs={12}>
                    <Typography align="center">
                      {t('bookings:bookings.viewBooking.validation.date', {
                        date: parseISO(booking.validated_at)
                      })}
                    </Typography>
                  </Grid>
                )}
                {booking.status !== 'done' && (
                  <>
                    {booking.type === 'pre_treatment' && (
                      <Grid item xs={12} sm={6}>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="secondary"
                          onClick={handleDecisionClick}
                        >
                          {isEligible
                            ? t(
                                'bookings:bookings.viewBooking.refusal.notEligible'
                              )
                            : t('bookings:bookings.viewBooking.refusal.cancel')}
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <DialogWithButton
                        buttonText={t(
                          'bookings:bookings.viewBooking.validation.title'
                        )}
                        title={t(
                          'bookings:bookings.viewBooking.validation.title'
                        )}
                        description={t(
                          'bookings:bookings.viewBooking.validation.confirm'
                        )}
                        onAccept={handleSubmit}
                        disabled={hasValidationErrors}
                        buttonProps={{
                          fullWidth: true
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </form>
        );
      }}
    />
  );
};

export default withStyles(styles)(Booking);
