import React from 'react';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ListItemText, Typography } from '@material-ui/core';

const Comment = ({ comment }) => {
  const { t } = useTranslation('common');

  return (
    <ListItemText
      primary={comment.content}
      secondary={
        <Typography color="textSecondary" variant="caption">
          {t('common:common.date.datetime', {
            date: parseISO(comment.created_at)
          })}
        </Typography>
      }
    />
  );
};

export default Comment;
