export const VERSION_INITIAL = 'initial';
export const VERSION_WITH_ALIGNERS = 'with_aligners';

export const angles = [
  {
    name: 'occlussal_upper'
  },
  {
    name: 'occlussal_lower'
  },
  {
    name: 'buccal_left'
  },
  {
    name: 'buccal_right'
  },
  {
    name: 'labial_anterior'
  }
];

export const anglesWithAligners = [
  {
    name: 'buccal_left'
  },
  {
    name: 'buccal_left_aligners'
  },
  {
    name: 'buccal_right'
  },
  {
    name: 'buccal_right_aligners'
  },
  {
    name: 'labial_anterior'
  },
  {
    name: 'labial_anterior_aligners'
  }
];

export const getAnglesFromVersion = (version) => {
  if (version === VERSION_WITH_ALIGNERS) {
    return anglesWithAligners;
  }
  return angles;
};
