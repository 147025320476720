import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isDate from 'validator/lib/isDate';
import isPast from 'date-fns/isPast';
import isToday from 'date-fns/isToday';
import isFuture from 'date-fns/isFuture';
import format from 'date-fns/format';
import addHours from 'date-fns/addHours';

const currentDate = new Date();
currentDate.setMinutes(0, 0, 0);

export const initialValues = {
  user: {
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    birthday: ''
  },
  address: {
    addressValue: '',
    city: '',
    postal_code: '',
    country: 'FR'
  },
  booking: {
    date: format(currentDate, 'yyyy-MM-dd'),
    time: format(addHours(currentDate, 1), 'HH:mm')
  }
};

export const validate = (t, { user, address, booking }) => {
  const { firstname, lastname, email, mobile, birthday } = user || {};
  const { address: addressValue, city, postal_code, country } = address || {};
  const { date, time } = booking || {};

  const errors = {};
  const userErrors = {};
  const addressErrors = {};
  const bookingErrors = {};

  // **************************************************************************
  // User personal data
  // **************************************************************************
  if (user) {
    if (!firstname) {
      userErrors.firstname = t('validation:validation.mandatory');
    }

    if (!lastname) {
      userErrors.lastname = t('validation:validation.mandatory');
    }

    if (!email) {
      userErrors.email = t('validation:validation.mandatory');
    } else if (!isEmail(email)) {
      userErrors.email = t('validation:validation.email');
    }

    if (!mobile) {
      userErrors.mobile = t('validation:validation.mandatory');
    } else if (!isMobilePhone(mobile)) {
      userErrors.mobile = t('validation:validation.mobile');
    }

    if (!birthday) {
      userErrors.birthday = t('validation:validation.mandatory');
    } else if (!isDate(birthday)) {
      userErrors.birthday = t('validation:validation.incorrectDate');
    } else if (isFuture(new Date(date)) && !isToday(new Date(date))) {
      bookingErrors.date = t('validation:validation.maxDate');
    }
  } else {
    userErrors.firstname = t('validation:validation.mandatory');
    userErrors.lastname = t('validation:validation.mandatory');
    userErrors.email = t('validation:validation.mandatory');
    userErrors.mobile = t('validation:validation.mandatory');
    userErrors.birthday = t('validation:validation.mandatory');
  }

  // **************************************************************************
  // User address data
  // **************************************************************************
  if (address) {
    if (!addressValue) {
      addressErrors.addressValue = t('validation:validation.mandatory');
    }

    if (!city) {
      addressErrors.city = t('validation:validation.mandatory');
    }

    if (!postal_code) {
      addressErrors.postal_code = t('validation:validation.mandatory');
    }

    if (!country) {
      addressErrors.country = t('validation:validation.mandatory');
    }
  } else {
    addressErrors.addressValue = t('validation:validation.mandatory');
    addressErrors.city = t('validation:validation.mandatory');
    addressErrors.postal_code = t('validation:validation.mandatory');
    addressErrors.country = t('validation:validation.mandatory');
  }

  // **************************************************************************
  // Booking data
  // **************************************************************************
  if (booking) {
    if (!date) {
      bookingErrors.date = t('validation:validation.mandatory');
    } else if (!isDate(date)) {
      bookingErrors.date = t('validation:validation.incorrectDate');
    } else if (isPast(new Date(date)) && !isToday(new Date(date))) {
      bookingErrors.date = t('validation:validation.minDate');
    }

    if (!time) {
      bookingErrors.time = t('validation:validation.mandatory');
    }
  } else {
    bookingErrors.date = t('validation:validation.mandatory');
    bookingErrors.time = t('validation:validation.mandatory');
  }

  if (Object.keys(userErrors)) {
    errors.user = { ...userErrors };
  }

  if (Object.keys(addressErrors)) {
    errors.address = { ...addressErrors };
  }

  if (Object.keys(bookingErrors)) {
    errors.booking = { ...bookingErrors };
  }

  return errors;
};
