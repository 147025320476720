import React from 'react';
import { Route, Switch } from 'react-router-dom';
import qs from 'query-string';

import { withStyles } from '@material-ui/core/styles';

import Patients from './patients/Main';
import Bookings from './bookings/Main';
import Agenda from './agenda/Main';
import Facturation from './facturation/Main';
import Dashboard from './dashboard/Main';
import BookingRequests from './bookingRequests/Main';
import Stripe from './stripe/Stripe';
import Pickups from './pickups/Main';
import Invoices from './invoices/Main';

const styles = (theme) => ({
  root: {
    height: '100%',
    margin: theme.spacing(2),

    '@media (min-width: 960px)': {
      marginLeft: 240
    }
  }
});

const Content = ({ classes, user, fetchUser }) => {
  return (
    <div className={classes.root}>
      <Switch>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Route exact path="/" render={(props) => <Dashboard {...props} />} />
        <Route path="/(patient|patients)" component={Patients} />
        <Route path="/consultations">
          <Bookings />
        </Route>
        <Route path="/agenda/:tab?" component={() => <Agenda user={user} />} />
        <Route path="/facturation" component={Facturation} />
        <Route path="/reservations" component={BookingRequests} />
        <Route path="/pickups" component={Pickups} />
        <Route path="/invoices" component={Invoices} />
        <Route
          path="/stripe"
          render={({ location: { search } }) => (
            <Stripe
              query={qs.parse(search)}
              user={user}
              fetchUser={fetchUser}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default withStyles(styles)(Content);
