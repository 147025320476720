import { Box, Button } from '@material-ui/core';
import React from 'react';
import useLabReport from './useLabReport';

const DownloadLabReport = ({ patientId }) => {
  const { labReport } = useLabReport(patientId);

  if (!labReport) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="center">
      <Button
        variant="contained"
        color="primary"
        href={labReport.url}
        target="_blank"
      >
        Télécharger le Lab report
      </Button>
    </Box>
  );
};

export default DownloadLabReport;
