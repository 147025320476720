import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from '@material-ui/icons/Search';

import { differenceInDays, addDays, parseISO } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import RemainingAligners from './RemainingAligners';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }
});

const Aligners = ({
  patient: { aligners },
  remainingAligners,
  patient,
  classes
}) => {
  const { t } = useTranslation(['patients', 'common']);
  return (
    <Paper className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {t('patients:patients.listAligners.title')}
        </Typography>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('patients:patients.listAligners.serie')}</TableCell>
            <TableCell>{t('patients:patients.listAligners.start')}</TableCell>
            <TableCell>{t('patients:patients.listAligners.end')}</TableCell>
            <TableCell>
              {t('patients:patients.listAligners.daysWorn')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {aligners.map((a) => {
            const daysWorn = differenceInDays(
              parseISO(a.finished_at),
              parseISO(a.started_at)
            );
            return (
              <TableRow key={a.id}>
                <TableCell component="th" scope="row">
                  <Typography variant="body2">{a.serie}</Typography>
                </TableCell>
                <TableCell>
                  {t('common:common.date.date', {
                    date: parseISO(a.started_at)
                  })}
                </TableCell>
                <TableCell>
                  {a.finished_at ? (
                    <>
                      <Typography>
                        {t('common:common.date.date', {
                          date: parseISO(a.finished_at)
                        })}
                      </Typography>
                    </>
                  ) : (
                    <Typography color="textSecondary">
                      {t('common:common.date.date', {
                        date: addDays(parseISO(a.started_at), 15)
                      })}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {a.finished_at ? (
                    <Typography
                      color={
                        daysWorn < 15 || daysWorn > 20 ? 'error' : 'initial'
                      }
                    >
                      {t('patients:patients.listAligners.daysWornValue', {
                        days: daysWorn
                      })}
                    </Typography>
                  ) : null}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    size="small"
                    aria-label="View aligner"
                    component={Link}
                    to={`/patient/${patient.id}/treatment/aligner/${a.serie}`}
                  >
                    <SearchIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
          <RemainingAligners remainingAligners={remainingAligners} />
        </TableBody>
      </Table>
    </Paper>
  );
};

export default withStyles(styles)(Aligners);
