export const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(12)
    }
  },

  block: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),

    borderRadius: 16
  },

  header: {
    marginBottom: theme.spacing(4),

    fontSize: 24,
    fontWeight: 300
  },

  row: {
    display: 'flex',
    gap: theme.spacing(4)
  },

  subHeader: {
    fontSize: 18,
    fontWeight: 400
  },

  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(2)
  },

  selectLabelShrink: {
    transform: 'translate(0px, -6px) scale(0.75) !important'
  }
});
