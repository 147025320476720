import { useEffect, useState } from 'react';
import { getFile } from '../../api/files';

const useLabReport = (patientId) => {
  const [labReport, setLabReport] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchFile = async () => {
      setIsFetching(true);
      try {
        const fetchedFile = await getFile({
          receiver_type: 'patient',
          receiver_id: patientId,
          type: 'treatment_document'
        });

        if (!fetchedFile.url) {
          return;
        }

        setLabReport(fetchedFile);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchFile();
  }, [patientId]);

  return { labReport, isFetching };
};

export default useLabReport;
