import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ListSubheader from '@material-ui/core/ListSubheader';

import { withStyles } from '@material-ui/core/styles';
import { Box, ListItemAvatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { v2 } from '../../axios';
import Loadable from '../Loadable';

const styles = () => ({
  root: {
    height: 280,
    overflowY: 'auto'
  }
});

const Availabilities = ({ classes }) => {
  const [availabilities, setAvailabilities] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation(['dashboard', 'common']);

  useEffect(() => {
    const fetchAvailabilities = async () => {
      setIsFetching(true);

      try {
        const { data: fetchedAvailabilities } = await v2.get('/availabilities');
        setAvailabilities(fetchedAvailabilities);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };
    fetchAvailabilities();
  }, []);

  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Paper className={classes.root}>
        <List
          subheader={
            <ListSubheader component="div" color="primary">
              {t('dashboard:dashboard.availabilities.title')}
            </ListSubheader>
          }
        >
          <Loadable loading={isFetching}>
            {availabilities.length ? (
              <>
                {availabilities.slice(0, 3).map((a) => (
                  <ListItem key={a.id}>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarTodayIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('common:common.date.detailed', {
                        date: parseISO(a.start)
                      })}
                      secondary={`${t('common:common.date.time', {
                        date: parseISO(a.start)
                      })} - ${t('common:common.date.time', {
                        date: parseISO(a.end)
                      })}`}
                    />
                  </ListItem>
                ))}
              </>
            ) : (
              <ListItem>
                <ListItemText
                  primary={t('dashboard:dashboard.availabilities.empty')}
                />
              </ListItem>
            )}
            <Box display="flex" justifyContent="center">
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
                component={Link}
                to="/agenda"
              >
                {t('dashboard:dashboard.availabilities.manage')}
              </Typography>
            </Box>
          </Loadable>
        </List>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(Availabilities);
