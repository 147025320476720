import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import TextField from '../../../final-form/TextField';

import { getPhoneCountryCode } from '../helpers';
import { styles } from '../styles';

const Contacts = ({ country, classes }) => {
  const { t } = useTranslation('patients');

  return (
    <Paper variant="outlined" className={classes.block}>
      <Typography className={classes.subHeader}>
        {t('patients:patients.create.subHeaders.contacts')}
      </Typography>

      <Box className={classes.row}>
        <Field
          name="user.email"
          label={t('patients:patients.create.user.email')}
          type="email"
          component={TextField}
          fullWidth
        />
        <Field
          name="user.mobile"
          label={t('patients:patients.create.user.mobile')}
          type="tel"
          component={TextField}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                +{getPhoneCountryCode(country)}
              </InputAdornment>
            )
          }}
        />
      </Box>
    </Paper>
  );
};

export default withStyles(styles)(Contacts);
