import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';

import { v2Base } from '../../../axios';
import { SnackbarContext } from '../../Snackbar';
import { UserContext } from '../../../store/User';

import Address from './Form/Address';
import Personal from './Form/Personal';
import Contacts from './Form/Contacts';
import Booking from './Form/Booking';

import { getPhoneCountryCode } from './helpers';
import { validate, initialValues } from './validation';
import { styles } from './styles';

const CreatePatient = ({ classes }) => {
  const { t } = useTranslation(['patients', 'validation']);
  const history = useHistory();
  const snackbar = useContext(SnackbarContext);
  const { id } = useContext(UserContext);

  const handleError = (errors) =>
    Object.entries(errors).reduce((acc, [key, value]) => {
      if (key.includes('.')) {
        const [parentKey, ...childKeys] = key.split('.');
        const childKey = childKeys.join('.');

        acc[parentKey] = handleError({ [childKey]: value });
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        acc[key] = handleError(value);
      } else {
        acc[key] = value.join(' ');
      }

      return acc;
    }, {});

  const handleSubmit = async ({ user, booking, address }) => {
    try {
      const userPayload = {
        type: 'referred',
        user: {
          ...user,
          mobile: getPhoneCountryCode(address.country) + user.mobile
        },
        address
      };
      const { data: userData } = await v2Base.post('/patients', userPayload);

      const bookingPayload = {
        type: 'pre_treatment',
        date: `${booking.date} ${booking.time}`,
        patient_id: userData.id,
        practician_id: id
      };
      const { data: bookingData } = await v2Base.post(
        '/bookings',
        bookingPayload
      );

      history.push(`/consultations/${bookingData.id}`);
    } catch (error) {
      snackbar.open(error?.response?.data?.message || error.message, 'error');

      if (error?.response?.data?.errors) {
        return handleError(error.response.data.errors);
      }
    }

    return undefined;
  };

  const handleValidate = (formValues) => {
    return validate(t, formValues);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h1" className={classes.header}>
        {t('patients:patients.create.header')}
      </Typography>

      <Form
        onSubmit={handleSubmit}
        validate={handleValidate}
        initialValues={initialValues}
        render={({ handleSubmit: onSubmit, submitting, values }) => (
          <form onSubmit={onSubmit}>
            <Personal />
            <Address />
            <Contacts country={values.address.country} />
            <Booking />

            <Box className={classes.buttons}>
              <Button component={Link} to="/patients" variant="contained">
                {t('patients:patients.create.cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting}
              >
                {t('patients:patients.create.submit')}
              </Button>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

export default withStyles(styles)(CreatePatient);
