import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Typography, Button, Select, MenuItem } from '@material-ui/core';

import { v2Base } from '../../../../axios';

import { TagsContext } from '../../../../store/Tags';
import { SnackbarContext } from '../../../Snackbar';
import { Tag } from './Tag';
import { ADD_NEW_TAG_PLACEHOLDER, getTagClassNameByType } from './helpers';

import { useCustomStyles } from './styles';

export function Tags({ patientTags, onSave, patientId }) {
  const { t } = useTranslation('patients');
  const cls = useCustomStyles();
  const tags = useContext(TagsContext);
  const snackbar = useContext(SnackbarContext);

  const [activeTags, setActiveTags] = useState(patientTags);
  const [availableTags, setAvailableTags] = useState(tags);

  useEffect(() => {
    setActiveTags(patientTags);
  }, [patientTags.length]);

  useEffect(() => {
    const newAvailableTags = tags.filter((tag) => {
      return !activeTags.some((activeTag) => {
        return activeTag.id === tag.id;
      });
    });
    setAvailableTags(newAvailableTags);
  }, [activeTags]);

  const updateTags = async () => {
    try {
      const { data: newTags } = await v2Base.put(
        `/patients/${patientId}/tags`,
        {
          tags: activeTags.map((item) => item.id)
        }
      );
      onSave(newTags);
      snackbar.open(t('patients.view.tags.update.success'), 'success');
    } catch (err) {
      snackbar.open(t('patients.view.tags.update.error'), 'error');
    }
  };

  const handleDelete = (removedItemId) => {
    setActiveTags(activeTags.filter((item) => item.id !== removedItemId));
  };

  const handleAddNewTag = (event) => {
    const newTag = availableTags.find((item) => item.id === event.target.value);
    setActiveTags([...activeTags, newTag]);
  };

  return (
    <Paper className={cls.root}>
      <Typography variant="h1" className={cls.header}>
        {t('patients.view.tags.title')}
      </Typography>
      <div className={cls.tags}>
        {activeTags.map((item) => (
          <Tag
            key={item.id}
            onDelete={() => handleDelete(item.id)}
            data={item}
            className={cls.tag}
          />
        ))}
        {!!availableTags.length && (
          <Select
            onChange={handleAddNewTag}
            value={ADD_NEW_TAG_PLACEHOLDER}
            className={cls.dropdown}
            classes={{ icon: cls.dropdownIcon }}
            MenuProps={{
              classes: {
                paper: cls.dropdownMenu,
                list: cls.dropdownList
              }
            }}
          >
            <MenuItem
              value={ADD_NEW_TAG_PLACEHOLDER}
              className={cls.placeholder}
            >
              {t('patients.view.tags.add')}
            </MenuItem>
            {availableTags.map((item) => (
              <MenuItem
                key={item.id}
                value={item.id}
                className={getTagClassNameByType(item.type, cls)}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
      <Button variant="contained" color="primary" onClick={updateTags}>
        {t('patients.view.tags.save')}
      </Button>
    </Paper>
  );
}
