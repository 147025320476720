import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

import { v2 } from '../../../../axios';

import { SnackbarContext } from '../../../Snackbar';
import TextField from '../../../final-form/TextField';

import { validate, getInitialValues } from './helpers';
import { useCustomStyles } from './ReScheduleBooking.styles';

export const ReScheduleBooking = ({ bookingId, initialDate, onSave }) => {
  const { t } = useTranslation('bookings');
  const cls = useCustomStyles();

  const snackbar = useContext(SnackbarContext);

  const [open, setOpen] = useState(false);

  const initialValues = getInitialValues(initialDate);

  const toggleModal = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleValidate = (values) => {
    return validate(values, t);
  };

  const handleSubmit = async (values) => {
    try {
      const newBookingDate = `${values.date} ${values.time}:00`;

      const { data } = await v2.put(`/bookings/${bookingId}/reschedule`, {
        date: newBookingDate
      });

      onSave(data.date);
      setOpen(false);
      snackbar.open(t('bookings.reschedule.submitMessages.success'), 'success');
    } catch (error) {
      snackbar.open(t('bookings.reschedule.submitMessages.error'), 'error');
    }
  };

  return (
    <>
      <Button
        onClick={toggleModal}
        variant="outlined"
        color="primary"
        fullWidth
        className={cls.btn}
      >
        {t('bookings.reschedule.button')}
      </Button>

      <Dialog
        open={open}
        onClose={toggleModal}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {t('bookings.reschedule.title')}
        </DialogTitle>
        <Form
          onSubmit={handleSubmit}
          validate={handleValidate}
          initialValues={initialValues}
          render={({
            handleSubmit: onSubmit,
            submitting,
            hasValidationErrors,
            errors,
            touched
          }) => (
            <>
              <DialogContent>
                <form
                  onSubmit={onSubmit}
                  className={cls.form}
                  id="reschedule-booking"
                >
                  <Field
                    name="date"
                    label={t('bookings.reschedule.label.date')}
                    component={TextField}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={cls.field}
                    error={(touched.date || touched.time) && errors.date}
                  />
                  <Field
                    name="time"
                    label={t('bookings.reschedule.label.time')}
                    component={TextField}
                    type="time"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={cls.field}
                    error={(touched.date || touched.time) && errors.time}
                  />
                </form>
              </DialogContent>
              <DialogActions className={cls.btns}>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={toggleModal}
                >
                  {t('bookings.reschedule.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  form="reschedule-booking"
                  disabled={submitting || hasValidationErrors}
                >
                  {t('bookings.reschedule.save')}
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
