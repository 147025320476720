import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Review from '../Review';

import FetchFile from '../../../../../FetchFile';
import { v2 } from '../../../../../../axios';

import { getAnglesFromVersion } from '../../../../../../constants/photos.constant';

const AdditionalReviews = ({ aligner, patient, onReview }) => {
  const angles = getAnglesFromVersion(aligner.version);
  const { t } = useTranslation(['patients', 'photos']);

  if (!aligner.additional_reviews) {
    return null;
  }

  const handleReview = async (additionalReviewId, values) => {
    try {
      const { data } = await v2.post(
        `/additional-reviews/${additionalReviewId}/review`,
        values
      );

      await onReview(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.response);
    }
  };

  return (
    <Grid item container spacing={2}>
      {aligner.additional_reviews.map((additionalReview) => {
        if (!additionalReview.requested_review_at) {
          return null;
        }
        return (
          <Fragment key={additionalReview.id}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {t('patients:patients.viewAligner.additionalReview.title')}
              </Typography>
            </Grid>
            {angles.map((angle) => (
              <Grid key={angle.name} item xs={12} sm={6} md={4}>
                <FetchFile
                  receiverId={patient.id}
                  receiverType="patient"
                  resourceId={additionalReview.id}
                  resourceType="additional_review"
                  type="treatment_photo"
                  meta={{
                    angle: angle.name,
                    version: aligner.version,
                    additional_review_id: additionalReview.id,
                    additional_review: 'true'
                  }}
                  fileName={`photo-serie-${aligner.serie}-${angle.name}-${patient.reference}`}
                  showNotFound
                  notFoundMessage="No picture"
                  description={t(`photos:photos.angles.${angle.name}`)}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Review
                review={additionalReview}
                onReview={async (values) => {
                  await handleReview(additionalReview.id, values);
                }}
              />
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default AdditionalReviews;
