export const styles = (theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',

    gap: theme.spacing(2),
    margin: theme.spacing(0, 0, 2, 0),

    fontSize: 20,
    fontWeight: 600
  },

  error: {
    color: theme.palette.error.dark,
    fontWeight: 500
  }
});
