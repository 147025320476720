import { axiosWithoutCredentials } from '../axios';

export const isMimeTypeImage = (mimeType) =>
  ['image/gif', 'image/jpeg', 'image/png'].includes(mimeType);

export const getFileContent = async (url) => {
  const { data: content } = await axiosWithoutCredentials.get(url, {
    responseType: 'arraybuffer'
  });

  return content;
};
