import React from 'react';

import {
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  withStyles,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { STATUS_CONFIRMED, STATUS_DONE } from '../bookings.utils';

const styles = (theme) => ({
  formContainer: {
    paddingRight: theme.spacing(2)
  }
});

const SelectStatus = ({ handleChangeFunction, classes, value }) => {
  const { t } = useTranslation('bookings');
  return (
    <div className={classes.formContainer}>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="select-multiple-chip">
              {t('bookings:bookings.list.selectStatus.title')}
            </InputLabel>
            <Select
              value={value}
              onChange={(e) => handleChangeFunction(e)}
              input={<Input id="status" />}
            >
              {[STATUS_CONFIRMED, STATUS_DONE].map((status) => (
                <MenuItem key={status} value={status}>
                  {t(`bookings:bookings.list.selectStatus.${status}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(SelectStatus);
