import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { isMonitoringPractician } from '../../../../utils/patients.utils';

const TreatmentNotStarted = ({ patient }) => {
  const { t } = useTranslation(['patients', 'common']);
  const formattedDate = t('common:common.date.detailed', {
    date: parseISO(patient.decision_practician_at)
  });
  return (
    <Box mt={2}>
      <Paper>
        <Box p={2}>
          {isMonitoringPractician(patient) && (
            <Typography align="center" gutterBottom>
              {patient.decision_practician
                ? t('patients:patients.view.notStarted.decisionAccepted')
                : t('patients:patients.view.notStarted.decisionRefused')}
              {formattedDate}
            </Typography>
          )}
          <Typography align="center">
            {t('patients:patients.view.notStarted.description')}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default TreatmentNotStarted;
