import React from 'react';
import { Button } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TextField from '../../../final-form/TextField';
import { v2 } from '../../../../axios';

const AddComment = ({ patient, onAdd }) => {
  const { t } = useTranslation('comments');
  const createComment = async (values, form) => {
    try {
      await v2.post(
        'comments',
        {
          content: values.comment
        },
        {
          params: {
            patient_id: patient.id
          }
        }
      );
      await onAdd();
      setTimeout(form.restart);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.comment) {
      errors.comment = t('comments:comments.add.mandatory');
    }

    return errors;
  };

  return (
    <Form
      onSubmit={createComment}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="comment"
            fullWidth
            label={t('comments:comments.add.label')}
            component={TextField}
          />

          <Button
            disabled={submitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            {submitting
              ? t('comments:comments.add.submitting')
              : t('comments:comments.add.submit')}
          </Button>
        </form>
      )}
    />
  );
};

export default AddComment;
