import React, { Fragment, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import ListSubheader from '@material-ui/core/ListSubheader';

import { withStyles } from '@material-ui/core/styles';

import { ListItemAvatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { v2 } from '../../axios';
import Loadable from '../Loadable';

const styles = () => ({
  root: {
    height: 280,
    overflowY: 'auto'
  }
});

const Reviews = ({ classes }) => {
  const { t } = useTranslation(['dashboard', 'common']);
  const [reviews, setReviews] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setIsFetching(true);
        const { data: fetchedReviews } = await v2.get('aligners', {
          params: { status: 'required-review' }
        });

        setReviews(fetchedReviews);
      } finally {
        setIsFetching(false);
      }
    };

    fetchReviews();
  }, []);

  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Paper className={classes.root}>
        <List
          subheader={
            <ListSubheader component="div" color="primary">
              {t('dashboard:dashboard.reviews.title')}
            </ListSubheader>
          }
        >
          <Loadable loading={isFetching}>
            {reviews.length ? (
              <>
                {reviews.map((review) => (
                  <ListItem
                    button
                    component={Link}
                    to={`/patient/${review.patient.id}/treatment/aligner/${review.serie}`}
                    key={review.id}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={review.patient.user.fullname}
                      secondary={t('common:common.date.ago', {
                        date: parseISO(review.requested_review_at)
                      })}
                    />
                  </ListItem>
                ))}
              </>
            ) : (
              <ListItem>
                <ListItemText
                  primary={t('dashboard:dashboard.reviews.empty')}
                />
              </ListItem>
            )}
          </Loadable>
        </List>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(Reviews);
