import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Breadcrumbs from './Breadcrumbs';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  }
});

const Header = ({ classes, breadcrumbs, title }) => (
  <div className={classes.root}>
    <Typography variant="h5">{title}</Typography>

    {breadcrumbs && <Breadcrumbs steps={breadcrumbs} />}
  </div>
);

export default withStyles(styles)(Header);
