import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { isValid } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { v2 } from '../../../axios';
import TextField from '../../final-form/TextField';
import Loadable from '../../Loadable';

const SearchPatients = ({ onSearch }) => {
  const { t } = useTranslation(['bookings', 'validation']);
  const [noResults, setNoResults] = useState(false);

  const onSubmit = async (values) => {
    setNoResults(false);
    try {
      const { data } = await v2.get('/unexpected-bookings', {
        params: values
      });

      if (!data.length) {
        setNoResults(true);
      }

      onSearch(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.mobile) {
      errors.mobile = t('validation:validation.mandatory');
    }

    if (!values.birthday) {
      errors.birthday = t('validation:validation.mandatory');
    }

    if (!isValid(parseISO(values.birthday))) {
      errors.birthday = t('validation:validation.incorrectDate');
    }

    return errors;
  };

  return (
    <Box p={2}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  {t('bookings:bookings.unexpected.search.description')}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  name="mobile"
                  label={t('bookings:bookings.unexpected.search.mobile')}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  name="birthday"
                  label={t('bookings:bookings.unexpected.search.birthday')}
                  component={TextField}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              {noResults && (
                <Grid item xs={12}>
                  <Typography color="error">
                    {t('bookings:bookings.unexpected.results.noResults')}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                >
                  <Loadable loading={submitting} size={20} noMessage>
                    {t('bookings:bookings.unexpected.search.confirm')}
                  </Loadable>
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Box>
  );
};

export default SearchPatients;
