const healthQuestionsByCategories = [
  {
    name: 'personal_dental_health',
    en: 'Personal dental health confirmation',
    fr: 'Confirmation état de santé bucco-dentaire',
    es: 'Confirmación del estado de salud bucodental',
    questions: [
      {
        name: 'cleaned_professional_last_6_months',
        expectedAnswer: 'yes',
        en: `I had my teeth professionally cleaned by my dentist in the last 6 months`,
        fr: `Mes dents ont été nettoyées par un dentiste au cours des 6 derniers mois`,
        es: `Mis dientes han sido limpiados por un dentista en los últimos 6 meses`,
        type: 'radio'
      },
      {
        name: 'xray_last_12_months',
        expectedAnswer: 'yes',
        en: `I had x-rays taken of my teeth in the last 12 months`,
        fr: `Une radiographie panoramique de mes dents a été prise au cours des 12 derniers mois`,
        es: `Se ha realizado una radiografía panorámica de mis dientes en los últimos 12 meses`,
        type: 'radio'
      },
      {
        name: 'issues_detected_fixed',
        expectedAnswer: 'yes',
        en: `I had my teeth checked for decay, loose & broken fillings, and any issues detected have been fixed`,
        fr: `J’ai fait examiner mes dents à la recherche de caries et/ou de plombages endommagés, et tout problème détecté a été réglé`,
        es: `Me examinaron los dientes en busca de caries y/o empastes dañados, y se arreglaron los problemas que se encontraron`,
        type: 'radio'
      },
      {
        name: 'xray_no_shortened_tooth_roots',
        expectedAnswer: 'yes',
        en: `I had my x-rays checked and I have no shortened or resorbed tooth roots`,
        fr: `Mes radiographies n’ont pas révélé de racine(s) dentaire(s) raccourcie(s) ou résorbée(s)`,
        es: `Mis radiografías no revelaron ninguna raíz dental acortada o reabsorbida`,
        type: 'radio'
      },
      {
        name: 'xray_no_impacted_teeth_jaws',
        expectedAnswer: 'yes',
        en: `I had my x-rays checked and I have no impacted/non-erupted teeth in my jaws `,
        fr: `Mes radiographies n’ont pas révélé de dent(s) éruptée(s), semi-incluse(s) ou incluse(s)`,
        es: `Mis radiografías no revelaron ningún diente o dientes erupcionados, semi-incluidos o impactados`,
        type: 'radio'
      },
      {
        name: 'no_gum_disease',
        expectedAnswer: 'yes',
        en: `I have my mouth checked by a dentist and I do not have gum disease`,
        fr: `Mon examen dentaire par un dentiste n’a pas révélé de maladie des gencives`,
        es: `Mi examen dental realizado por un dentista no reveló ninguna enfermedad de las encías`,
        type: 'radio'
      },
      {
        name: 'no_oral_cancer',
        expectedAnswer: 'yes',
        en: `I have my mouth checked for oral cancer by a dentist and I do not have oral cancer`,
        fr: `Mon examen dentaire par un dentiste n’a pas révélé de cancer oral`,
        es: `Mi examen dental realizado por un dentista no reveló ningún cáncer oral`,
        type: 'radio'
      },
      {
        name: 'no_pain_teeth',
        expectedAnswer: 'yes',
        en: `I have no pain in any of my teeth`,
        fr: `Je n’ai des douleurs au niveau d’aucune de mes dents`,
        es: `No tengo dolor en ninguno de mis dientes`,
        type: 'radio'
      },
      {
        name: 'no_pain_jaws',
        expectedAnswer: 'yes',
        en: `I have no pain in my jaws or difficulty in chewing`,
        fr: `Je n’ai pas de douleurs au niveau de la mâchoire ou des difficultés à l’ouvrir/fermer`,
        es: `No tengo dolor de mandíbula ni dificultad para abrir/cerrar la mandíbula`,
        type: 'radio'
      },
      {
        name: 'no_tmj',
        expectedAnswer: 'yes',
        en: `I have never been diagnosed with TMJ - Temporomandibular Joint Disorder`,
        fr: `Je n’ai jamais été diagnostiqué d’un trouble de l'articulation temporo-mandibulaire`,
        es: `Nunca me han diagnosticado un trastorno de la ATM`,
        type: 'radio'
      },
      {
        name: 'no_loose_teeth',
        expectedAnswer: 'yes',
        en: `I have no loose teeth`,
        fr: `Je n’ai pas de dents qui bougent`,
        es: `No tengo ningún diente que se mueva`,
        type: 'radio'
      },
      {
        name: 'no_implants_crowns_bridges_front',
        expectedAnswer: 'yes',
        en: `I have no implants, crowns, bridges or veneers on the front teeth (6 front teeth)`,
        fr: `Je n’ai pas de couronnes, bridges ou facettes sur les dents de devant`,
        es: `No tengo coronas, puentes o carillas en los dientes delanteros`,
        type: 'radio'
      },
      {
        name: 'no_implants_crowns_bridges',
        expectedAnswer: 'yes',
        en: `I have no dental implants, crowns, bridges or veneers`,
        fr: `Je n’ai pas d’implants, couronnes, bridges ou facettes`,
        es: `No tengo implantes, coronas, puentes o carillas`,
        type: 'radio'
      }
    ]
  },
  {
    name: 'general_health',
    en: 'General health',
    fr: 'État de santé général',
    es: 'Estado de salud general',
    questions: [
      {
        name: 'rheumatic_fever',
        expectedAnswer: 'no',
        en: `Rheumatic fever (Chorea St Vitus Dance)`,
        fr: `Fièvre rhumatismale (Sydenham's Chorea/St Vitus' Dance)`,
        es: `Fiebre reumática (corea de San Vito)`,
        type: 'checkbox'
      },
      {
        name: 'angina',
        expectedAnswer: 'no',
        en: `Angina`,
        fr: `Angine`,
        es: `Angina`,
        type: 'checkbox'
      },
      {
        name: 'heart_surgery',
        expectedAnswer: 'no',
        en: `Heart surgery`,
        fr: `Chirurgie du Cœur`,
        es: `Cirugía cardíaca`,
        type: 'checkbox'
      },
      {
        name: 'heart_attack',
        expectedAnswer: 'no',
        en: `Heart attack`,
        fr: `Infarctus`,
        es: `Ataque cardiaco`,
        type: 'checkbox'
      },
      {
        name: 'high_low_blood_pressure',
        expectedAnswer: 'no',
        en: `High / Low Blood Pressure`,
        fr: `Tension artérielle basse/haute`,
        es: `Presión arterial alta/baja`,
        type: 'checkbox'
      },
      {
        name: 'pacemaker',
        expectedAnswer: 'no',
        en: `Pacemaker`,
        fr: `Pacemaker`,
        es: `Marcapasos`,
        type: 'checkbox'
      },
      {
        name: 'heart_murmur',
        expectedAnswer: 'no',
        en: `Heart murmur`,
        fr: `Souffle cardiaque`,
        es: `Soplo cardíaco`,
        type: 'checkbox'
      },
      {
        name: 'replacement_heart_valve',
        expectedAnswer: 'no',
        en: `Replacement heart valve`,
        fr: `Valvules cardiaques artificielles`,
        es: `Válvulas cardíacas artificiales`,
        type: 'checkbox'
      },
      {
        name: 'asthma',
        expectedAnswer: 'no',
        en: `Asthma`,
        fr: `Asthme`,
        es: `Asma`,
        type: 'checkbox'
      },
      {
        name: 'bronchitis',
        expectedAnswer: 'no',
        en: `Bronchitis`,
        fr: `Bronchite`,
        es: `Bronquitis`,
        type: 'checkbox'
      },
      {
        name: 'tuberculosis',
        expectedAnswer: 'no',
        en: `Tuberculosis`,
        fr: `Tuberculose`,
        es: `Tuberculosis`,
        type: 'checkbox'
      },
      {
        name: 'epilepsy',
        expectedAnswer: 'no',
        en: `Epilepsy`,
        fr: `Epilepsie`,
        es: `Epilepsia`,
        type: 'checkbox'
      },
      {
        name: 'diabetes',
        expectedAnswer: 'no',
        en: `Diabetes`,
        fr: `Diabètes`,
        es: `Diabetes`,
        type: 'checkbox'
      },
      {
        name: 'family_history_diabetes',
        expectedAnswer: 'no',
        en: `Family History of Diabetes`,
        fr: `Antécédents familiaux – Diabètes`,
        es: `Antecedentes familiares de diabetes`,
        type: 'checkbox'
      },
      {
        name: 'jaundice',
        expectedAnswer: 'no',
        en: `Jaundice`,
        fr: `Jaunisse`,
        es: `Ictericia`,
        type: 'checkbox'
      },
      {
        name: 'hepatitis',
        expectedAnswer: 'no',
        en: `Hepatitis`,
        fr: `Hépatite`,
        es: `Hepatitis`,
        type: 'checkbox'
      },
      {
        name: 'haemophilia',
        expectedAnswer: 'no',
        en: `Haemophilia or other bleeding problems`,
        fr: `Hémophilie ou problèmes de saignement`,
        es: `Hemofilia u otros problemas hemorrágicos`,
        type: 'checkbox'
      },
      {
        name: 'family_history_haemophilia',
        expectedAnswer: 'no',
        en: `Family history of haemophilia or other bleeding problems`,
        fr: `Antécédents familiaux - Hémophilie ou problèmes de saignement`,
        es: `Antecedentes familiares de hemofilia u otros problemas hemorrágicos`,
        type: 'checkbox'
      },
      {
        name: 'sickle_cell_anaemia',
        expectedAnswer: 'no',
        en: `Sickle Cell anaemia`,
        fr: `Drépanocytose ou anémie à hématies falciformes`,
        es: `Anemia falciforme`,
        type: 'checkbox'
      },
      {
        name: 'family_history_sickle_cell_anaemia',
        expectedAnswer: 'no',
        en: `Family history of Sickle Cell anaemia`,
        fr: `Antécédents familiaux – Drépanocytose`,
        es: `Antecedentes familiares de anemia falciforme`,
        type: 'checkbox'
      }
    ]
  },
  {
    name: 'more_infos',
    en: 'More',
    fr: 'Información adicional',
    es: '',
    questions: [
      {
        name: 'medication',
        expectedAnswer: 'no',
        en: `Are you taking any medication? (e.g. injections, tablets, inhalers, ointments)`,
        fr: `Prenez-vous actuellement des médicaments ? (ex : injections, comprimés, inhalateurs, pommade)`,
        es: `¿Está tomando alguna medicación? (por ejemplo, inyecciones, pastillas, inhaladores, pomadas)`,
        type: 'radio'
      },
      {
        name: 'blood_tests',
        expectedAnswer: 'no',
        en: `Have you undergone any blood tests or other investigations?`,
        fr: `Avec-vous récemment effectué des tests sanguins ou autres investigations ?`,
        es: `¿Se ha sometido a algún análisis de sangre u otras investigaciones?`,
        type: 'radio'
      },
      {
        name: 'under_care_prolonged_illness',
        expectedAnswer: 'no',
        en: `Are you currently / have you recently been under the care of your Doctor? Have had a prolonged illness or being hospitalised?`,
        fr: `Etes-vous actuellement/avez été sous traitement de votre médecin ? Etes-vous actuellement/avez été malade sur une période prolongée et/ou hospitalisé(e) ?`,
        es: `¿Está actualmente o ha estado alguna vez en tratamiento con su médico? ¿Está actualmente/ha estado enfermo durante un largo período de tiempo y/o hospitalizado?`,
        type: 'radio'
      },
      {
        name: 'blood_borne_viruses',
        expectedAnswer: 'no',
        en: `Do you carry any blood borne viruses? (e.g. hepatitis B, hepatitis C, hiV)`,
        fr: `Êtes-vous porteur d’un virus à diffusion hématogène ? (ex : hépatite B, hépatite C, HIV)`,
        es: `¿Es usted portador de algún virus de transmisión sanguínea? (por ejemplo, hepatitis B, hepatitis C, hiV)`,
        type: 'radio'
      },
      {
        name: 'exposed_to_ionising_radiation_head_neck_region',
        expectedAnswer: 'no',
        en: `Have you been exposed to ionising radiation in the head and neck region?`,
        fr: `Avez-vous été exposé a des radiations ionisantes au niveau de la tête ou du cou ? `,
        es: `¿Ha estado expuesto a radiaciones ionizantes en la región de la cabeza y el cuello?`,
        type: 'radio'
      },
      {
        name: 'artificial_joints',
        expectedAnswer: 'no',
        en: `Do you have any artificial joints? (e.g. hip, knee)`,
        fr: `Avez-vous une prothèse articulaire ? (ex : hanche, genou)`,
        es: `¿Tiene alguna articulación artificial? (por ejemplo, cadera, rodilla)`,
        type: 'radio'
      },
      {
        name: 'medication_osteoporosis',
        expectedAnswer: 'no',
        en: `Are you taking any medication for osteoporosis? (e.g. bisphosphonates)`,
        fr: `Prenez-vous des médicaments pour traiter l’ostéoporose ? (ex : bisphosphonates)`,
        es: `¿Está tomando alguna medicación para la osteoporosis? (por ejemplo, bifosfonatos`,
        type: 'radio'
      },
      {
        name: 'pregnant',
        expectedAnswer: 'no',
        en: `Women only: Do you know, or suspect you might be pregnant?`,
        fr: `Femmes uniquement : êtes-vous ou pensez-vous être enceinte ? `,
        es: `Sólo para mujeres: ¿Sabe o sospecha que puede estar embarazada?`,
        type: 'radio'
      },
      {
        name: 'smoking',
        expectedAnswer: 'no',
        en: `Do you smoke?`,
        fr: `Fumez-vous ?`,
        es: `¿Fumas?`,
        type: 'radio'
      },
      {
        name: 'alcohol',
        expectedAnswer: 'no',
        en: `Do you drink alcohol?`,
        fr: `Buvez-vous de l’alcool ? `,
        es: `¿Bebe usted alcohol?`,
        type: 'radio'
      },
      {
        name: 'has_allergies',
        expectedAnswer: 'no',
        en: `Are you allergic to anything?`,
        fr: `Avez-vous des allergies ?`,
        es: `¿Es usted alérgico a algo?`,
        type: 'textfield',
        textfieldName: 'allergies'
      },
      {
        name: 'has_other_concerns',
        expectedAnswer: 'no',
        en: `Is there any medical condition that you need to discuss personally with your Dentist?`,
        fr: `Avez-vous un trouble médical dont vous aimeriez discuter personnellement avec votre orthodontiste ?`,
        es: `¿Hay alguna condición médica que deba discutir personalmente con su dentista?`,
        type: 'textfield',
        textfieldName: 'other_concerns'
      }
    ]
  }
];

const consultationQuestions = [
  {
    name: 'premolars',
    expectedAnswer: 'no',
    fr: 'Présence de couronnes, implants ou bridges ? (Préciser en commentaire)',
    en: 'Presence of crowns, implants or bridges ? (Specify in comment)',
    es: `¿Presencia de coronas, implantes o puentes? (Especifique en el comentario)`
  },
  {
    name: 'whitening',
    expectedAnswer: 'yes',
    fr: "Éligible solution de blanchiment (3,8% de perox. d'hydrogène)",
    en: 'Éligible whitening solution (3,8% hydrogen peroxide)',
    es: 'Apto para solución blanqueadora (peróxido de hidrógeno al 3,8%)'
  },
  {
    name: 'descaling',
    expectedAnswer: 'no',
    fr: 'Détartrage à effectuer ?',
    en: 'Descaling necessary?',
    es: '¿Es necesario el destartraje?'
  },
  {
    name: 'decay',
    expectedAnswer: 'no',
    fr: 'Présence de caries ?',
    en: 'Presence of decay?',
    es: '¿Presencia de caries?'
  },
  {
    name: 'fillings',
    expectedAnswer: 'no',
    fr: 'Obturations à revoir ?',
    en: 'Presence of loose or broken fillings?',
    es: '¿Presencia de empastes sueltos o rotos?'
  },
  {
    name: 'loose_teeth',
    expectedAnswer: 'no',
    fr: 'Dents mobiles ?',
    en: 'Presence of loose teeth?',
    es: '¿Presencia de dientes sueltos?'
  },
  {
    name: 'roots',
    expectedAnswer: 'no',
    fr: 'Présence de racine(s) dentaire(s) raccourcie(s) ou résorbée(s) ?',
    en: 'Shortened or resorbed tooth roots?',
    es: '¿Raíces dentales acortadas o reabsorbidas?'
  },
  {
    name: 'non_erupted',
    expectedAnswer: 'no',
    fr: 'Présence de dent(s) éruptée(s), semi-incluse(s) ou incluse(s) ?',
    en: 'Presence of impacted/non-erupted teeth?',
    es: '¿Presencia de dientes impactados/no erupcionados?'
  },
  {
    name: 'gum',
    expectedAnswer: 'no',
    fr: 'Présence de maladie(s) des gencives / problèmes de parodontie ?',
    en: 'Presence of gum disease?',
    es: '¿Presencia de enfermedad de las encías?'
  },
  {
    name: 'cancer',
    expectedAnswer: 'no',
    fr: 'Suspicion de cancer oral ?',
    en: 'Suspected oral cancer?',
    es: '¿Sospecha de cáncer oral?'
  },
  {
    name: 'tmj',
    expectedAnswer: 'no',
    fr: "Limitation de l'ouverture buccale / Problèmes d'ATM ?",
    en: 'Limited mouth opening / TMJ problem?',
    es: '¿Apertura limitada de la boca / problema de la ATM?'
  }
];

export { healthQuestionsByCategories, consultationQuestions };
