import { format, isSameDay, compareAsc } from 'date-fns';

export function validate(values, t) {
  const date = new Date(`${values.date} ${values.time}`);
  const currDate = new Date();
  const dateOrTimeInPastWasSelected = compareAsc(currDate, date) === 1;

  const errors = {};

  if (dateOrTimeInPastWasSelected) {
    if (isSameDay(date, currDate)) {
      errors.time = t('bookings.reschedule.validationErrors.time');
    } else {
      errors.date = t('bookings.reschedule.validationErrors.date');
    }
  }

  return errors;
}
export function getInitialValues(dateObj) {
  const initialValue = {
    date: undefined,
    time: undefined
  };

  if (dateObj) {
    initialValue.date = format(dateObj, 'yyyy-MM-dd');
    initialValue.time = format(dateObj, 'HH:mm');
  }

  return initialValue;
}
