import React from 'react';
import { Chip } from '@material-ui/core';

import { getTagClassNameByType } from './helpers';

import { useCustomStyles } from './styles';

export function Tag({ data, onDelete }) {
  const cls = useCustomStyles();

  const colors = getTagClassNameByType(data.type, cls);

  return (
    <Chip
      label={data.name}
      className={`${cls.tag} ${colors}`}
      onDelete={onDelete}
    />
  );
}
