import React from 'react';

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Upload from './Upload';

import angles from '../angles';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2)
  }
});

const PhotosUpload = ({
  serie,
  patient,
  readOnly,
  onSuccess,
  classes,
  resourceType,
  resourceId
}) => {
  const { t } = useTranslation('photos');
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      className={classes.root}
    >
      {angles
        .filter((angle) => {
          if (serie !== 'check_up' && angle.onlyCheckUp) {
            return false;
          }

          return true;
        })
        .map((angle) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={angle.name}>
            <Upload
              receiverId={patient.id}
              receiverType="patient"
              resourceId={resourceId}
              resourceType={resourceType}
              type="treatment_photo"
              backgroundImage={angle.background}
              meta={{
                angle: angle.name,
                serie
              }}
              fileName={`photo-${angle.name}-${serie}-${patient.reference}`}
              description={t(`photos:photos.angles.${angle.name}`)}
              retryable={false}
              autoUpload
              onSuccess={onSuccess}
              readOnly={readOnly}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default withStyles(styles)(PhotosUpload);
