import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Box, Button, Grid } from '@material-ui/core';
import { set } from 'date-fns';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import TextField from '../final-form/TextField';
import { v2Base } from '../../axios';
import Select from '../final-form/Select';
import Loadable from '../Loadable';
import {
  DEFAULT_MAX_TIME,
  DEFAULT_MIN_TIME,
  MIN_NB_PACKAGES,
  MAX_NB_PACKAGES,
  formatPickupDates,
  formatPickupHours,
  getHoursFromTime,
  MINIMUM_INTERVAL
} from '../../utils/pickups.utils';

const CreatePickup = ({ onCancel, onSuccess }) => {
  const { t, i18n } = useTranslation('pickups');
  const [pickupDates, setPickupDates] = useState(null);

  const fetchPickupDates = async () => {
    try {
      const { data } = await v2Base.get('pickup-dates');
      setPickupDates(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPickupDates();
  }, []);

  const handleCreate = async (values) => {
    const date = parseISO(values.date);
    const pickupDate = set(date, {
      hours: getHoursFromTime(values.min_time)
    });

    const formattedPickupDate = format(pickupDate, 'yyyy-MM-dd HH:mm');

    try {
      const { data } = await v2Base.post('/pickups', {
        ...values,
        pickup_date: formattedPickupDate,
        close_time: values.max_time
      });

      onSuccess(data);
      return undefined;
    } catch (error) {
      return error;
    }
  };

  const validate = (values) => {
    const errors = {};

    if (values.nb_packages < MIN_NB_PACKAGES) {
      errors.nb_packages = t('pickups:pickups.add.validation.minNbPackages', {
        min: MIN_NB_PACKAGES
      });
    }

    if (values.nb_packages > MAX_NB_PACKAGES) {
      errors.nb_packages = t('pickups:pickups.add.validation.maxNbPackages', {
        max: MAX_NB_PACKAGES
      });
    }

    // at least 2 hours between min and max
    if (values.min_time && values.max_time) {
      const hourMin = getHoursFromTime(values.min_time);
      const hourMax = getHoursFromTime(values.max_time);

      if (values.nb_packages < 1) {
        errors.min_time = t(
          'pickups:pickups.add.validation.minTimeBeforeMaxTime'
        );
      }

      if (hourMin >= hourMax) {
        errors.min_time = t(
          'pickups:pickups.add.validation.minTimeBeforeMaxTime'
        );
      }

      if (hourMax - hourMin < MINIMUM_INTERVAL) {
        errors.max_time = t('pickups:pickups.add.validation.minimumInterval', {
          interval: MINIMUM_INTERVAL
        });
      }
    }
    return errors;
  };

  if (!pickupDates) {
    return <Loadable loading />;
  }

  const dates = formatPickupDates(pickupDates.dates, t, i18n);
  const minHours = formatPickupHours(pickupDates.hours);
  const maxHours = formatPickupHours(pickupDates.close_times);

  return (
    <Form
      onSubmit={handleCreate}
      validate={validate}
      initialValues={{
        nb_packages: 1,
        date: dates[0].value,
        min_time: DEFAULT_MIN_TIME,
        max_time: DEFAULT_MAX_TIME
      }}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name="nb_packages"
                label={t('pickups:pickups.add.nbPackages')}
                component={TextField}
                type="number"
                variant="outlined"
                min={1}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="date"
                render={(props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Select {...props} formControlProps={{ fullWidth: true }} />
                )}
                label={t('pickups:pickups.add.pickupDate')}
                options={dates}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="min_time"
                render={(props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Select {...props} formControlProps={{ fullWidth: true }} />
                )}
                label={t('pickups:pickups.add.minTime')}
                options={minHours}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="max_time"
                render={(props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Select {...props} formControlProps={{ fullWidth: true }} />
                )}
                label={t('pickups:pickups.add.maxTime')}
                options={maxHours}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Box mr={2} component="span">
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={onCancel}
                    disabled={submitting}
                  >
                    {t('pickups:pickups.add.cancel')}
                  </Button>
                </Box>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                >
                  <Loadable loading={submitting} noMessage size={20}>
                    {t('pickups:pickups.add.confirm')}
                  </Loadable>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default CreatePickup;
