import React, { useEffect, useState } from 'react';

import {
  Grid as GridDX,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  SearchState,
  IntegratedFiltering
} from '@devexpress/dx-react-grid';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';

import { Box, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { v2 } from '../../axios';
import Loadable from '../Loadable';
import {
  STATUSES_COLOR,
  STATUS_PENDING
} from '../../utils/bookingRequests.utils';
import DialogCreateBookingRequest from './DialogCreateBookingRequest';

const ListBookingRequest = ({ snackbar }) => {
  const { t } = useTranslation(['bookingRequests', 'common']);

  const [bookingRequests, setBookingRequests] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const statusColumns = ['status'];
  const mobileColumns = ['mobile'];

  const fetchBookingRequests = async () => {
    setIsFetching(true);
    try {
      const { data: fetchedBookingRequests } = await v2.get(
        '/booking-requests'
      );
      setBookingRequests(fetchedBookingRequests);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleArchiving = async (bookingRequestId) => {
    try {
      await v2.delete(`booking-requests/${bookingRequestId}`);
      fetchBookingRequests();

      snackbar.open(
        t('bookingRequests:bookingRequests.list.archive.success'),
        'success'
      );
    } catch (error) {
      snackbar.open(error.response.data.message, 'error');
    }
  };

  const StatusFormatter = ({ row: bookingRequest }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography style={{ color: STATUSES_COLOR[bookingRequest.status] }}>
          {t(
            `bookingRequests:bookingRequests.list.table.status.${bookingRequest.status}`
          )}
        </Typography>
        {bookingRequest.status !== STATUS_PENDING && (
          <Tooltip
            title={t('bookingRequests:bookingRequests.list.archive.tooltip')}
          >
            <IconButton onClick={() => handleArchiving(bookingRequest.id)}>
              <DeleteForeverIcon color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  const MobileFormatter = ({ row: bookingRequest }) => {
    return (
      <a
        href={`tel:+${bookingRequest.mobile}`}
      >{`+${bookingRequest.mobile}`}</a>
    );
  };
  const StatusTypeProvider = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
  );
  const MobileTypeProvider = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DataTypeProvider formatterComponent={MobileFormatter} {...props} />
  );

  useEffect(() => {
    fetchBookingRequests();
  }, []);

  return (
    <Loadable loading={isFetching}>
      <Box p={2} display="flex" justifyContent="flex-end">
        <DialogCreateBookingRequest
          onSuccess={fetchBookingRequests}
          type="fab"
        />
      </Box>
      <GridDX
        rows={bookingRequests}
        columns={[
          {
            name: 'patient',
            title: t('bookingRequests:bookingRequests.list.table.patient'),
            getCellValue: (bookingRequest) => `${bookingRequest.patient}`
          },
          {
            name: 'mobile',
            title: t('bookingRequests:bookingRequests.list.table.mobile'),
            getCellValue: (bookingRequest) => `${bookingRequest.mobile}`
          },
          {
            name: 'date',
            title: t('bookingRequests:bookingRequests.list.table.date'),
            getCellValue: (bookingRequest) =>
              `${t('common:common.date.date', {
                date: parseISO(bookingRequest.date)
              })}`
          },
          {
            name: 'time',
            title: t('bookingRequests:bookingRequests.list.table.time'),
            getCellValue: (bookingRequest) =>
              `${t('common:common.date.time', {
                date: parseISO(bookingRequest.date)
              })}`
          },
          {
            name: 'status',
            title: t('bookingRequests:bookingRequests.list.table.status.title'),
            getCellValue: (bookingRequest) =>
              t(`bookingRequests:bookingRequests.type.${bookingRequest.status}`)
          }
        ]}
      >
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <StatusTypeProvider for={statusColumns} />
        <MobileTypeProvider for={mobileColumns} />
        <Table columnExtensions={['status', 'mobile']} />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel />
      </GridDX>
    </Loadable>
  );
};

export default ListBookingRequest;
