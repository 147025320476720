import React, { useState, useEffect } from 'react';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel
} from '@devexpress/dx-react-grid-material-ui';

import { DataTypeProvider, SearchState } from '@devexpress/dx-react-grid';

import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { v2 } from '../../../axios';

import Header from '../../Header';
import { debounce } from '../../../utils/debounce.utils';
import { styles } from './styles';

const codeColumns = ['patient'];

const CodeFormatter = ({ row: patient }) => (
  <Link to={`/patient/${patient.id}`}>{patient.user.fullname}</Link>
);

const CodeTypeProvider = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DataTypeProvider formatterComponent={CodeFormatter} {...props} />
);

const PatientsList = ({ match, classes }) => {
  const [patients, setPatients] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation(['patients', 'common']);

  const fetchPatients = async () => {
    const { status } = match.params;
    try {
      const { data } = await v2.get(`/patients`, {
        params: { query: searchValue, status }
      });
      setPatients(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onSearch = debounce(() => {
    fetchPatients();
  }, 400);

  useEffect(() => {
    onSearch();
  }, [searchValue]);

  return (
    <Paper>
      <Header
        breadcrumbs={[
          { name: t('patients:patients.list.title'), url: '/patients' }
        ]}
      />

      <Grid
        rows={patients}
        columns={[
          {
            name: 'patient',
            title: t('patients:patients.list.table.patient'),
            getCellValue: (patient) => patient.user.fullname
          },
          {
            name: 'treatment_duration',
            title: t('patients:patients.list.table.treatmentDuration'),
            getCellValue: (patient) =>
              patient.is_eligible
                ? t('patients:patients.list.table.treatmentDurationMonth', {
                    duration: patient.treatment_duration
                  })
                : t('patients:patients.list.table.notEligible')
          },
          {
            name: 'treatment_started_at',
            title: t('patients:patients.list.table.startDate'),
            getCellValue: (patient) => {
              return `${
                patient.treatment_started_at
                  ? t('common:common.date.date', {
                      date: parseISO(patient.treatment_started_at)
                    })
                  : t('patients:patients.list.table.notStartedYet')
              }`;
            }
          },
          {
            name: 'progression',
            title: t('patients:patients.list.table.progression'),
            getCellValue: (patient) => {
              return (
                patient.treatment_started_at &&
                patient.actual_aligners &&
                `${patient.actual_aligners.serie} / ${patient.nb_aligners}`
              );
            }
          }
        ]}
      >
        <SearchState onValueChange={setSearchValue} defaultValue="" />
        <CodeTypeProvider for={codeColumns} />
        <Table
          columnExtensions={['code']}
          messages={{ noData: t('patients:patients.list.table.noData') }}
        />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel
          messages={{
            searchPlaceholder: t('patients:patients.list.searchPlaceholder')
          }}
        />
        <Link to="/patients/create">
          <Tooltip
            title={t('patients:patients.list.create')}
            placement="bottom"
          >
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              className={classes.addPatientButton}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Link>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(PatientsList);
