import { useEffect, useRef, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import TextField from '../../../final-form/TextField';
import Select from '../../../final-form/Select';

import {
  COUNTRIES,
  loadGoogleMaps,
  createAutocompleteInstance
} from '../helpers';
import { styles } from '../styles';

const Address = ({ classes }) => {
  const { t } = useTranslation(['common', 'patients']);

  const addressInputRef = useRef(null);
  const { change } = useForm();

  const [isAutocompleteReady, setAutocompleteReady] = useState(false);

  const countries = [
    { value: COUNTRIES.FR, label: t('common:common.countries.fr') },
    { value: COUNTRIES.ES, label: t('common:common.countries.es') },
    { value: COUNTRIES.BE, label: t('common:common.countries.be') }
  ];

  const autocompleteAddress = (address) => {
    change('address', address);
  };

  useEffect(() => {
    loadGoogleMaps(() => {
      setAutocompleteReady(true);
    });
  }, []);

  useEffect(() => {
    let cleanupAutocompleteInstance = () => {};

    if (isAutocompleteReady) {
      cleanupAutocompleteInstance = createAutocompleteInstance(
        addressInputRef.current,
        autocompleteAddress
      );
    }

    return cleanupAutocompleteInstance;
  }, [isAutocompleteReady]);

  return (
    <Paper variant="outlined" className={classes.block}>
      <Typography className={classes.subHeader}>
        {t('patients:patients.create.subHeaders.address')}
      </Typography>

      <Field
        name="address.address"
        label={t('patients:patients.create.address.address')}
        component={TextField}
        fullWidth
        InputProps={{
          inputRef: addressInputRef,
          placeholder: ''
        }}
      />
      <Field
        name="address.city"
        label={t('patients:patients.create.address.city')}
        component={TextField}
        fullWidth
      />
      <Box className={classes.row}>
        <Field
          name="address.postal_code"
          label={t('patients:patients.create.address.postal_code')}
          component={TextField}
          fullWidth
        />
        <Field
          name="address.country"
          label={t('patients:patients.create.address.country')}
          component={Select}
          options={countries}
          formControlProps={{
            fullWidth: true
          }}
          inputLabelProps={{
            classes: {
              shrink: classes.selectLabelShrink
            }
          }}
          variant="standard"
        />
      </Box>
    </Paper>
  );
};

export default withStyles(styles)(Address);
