import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import ListComments from './ListComments';
import { v2 } from '../../../../axios';
import MyTextField from '../../../final-form/TextField';

const Comments = ({ patientId, practicianId, disabled }) => {
  const [comments, setComments] = useState([]);
  const { t } = useTranslation(['bookings']);

  const fetchComments = useCallback(async () => {
    try {
      const { data: fetchedComments } = await v2.get(`comments`, {
        params: {
          patient_id: patientId,
          practician_id: practicianId
        }
      });

      setComments(fetchedComments);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [patientId, practicianId]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments, disabled]);

  return (
    <Box>
      <Typography variant="subtitle1">
        {t('bookings:bookings.viewBooking.comment.title')}
      </Typography>
      <ListComments comments={comments} />
      <Field
        name="comment"
        label={t('bookings:bookings.viewBooking.comment.label')}
        disabled={disabled}
        component={MyTextField}
        rows={3}
        fullWidth
        multiline
        variant="outlined"
      />
    </Box>
  );
};

export default Comments;
