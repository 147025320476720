import { makeStyles } from '@material-ui/core';

export const useCustomStyles = makeStyles((theme) => ({
  btn: {
    marginBottom: theme.spacing(1)
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  field: {
    minWidth: 200,

    flex: 1,
    margin: theme.spacing(1, 2)
  },
  btns: {
    padding: theme.spacing(3)
  }
}));
