import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './list/ListBookings';
import Booking from './view/Booking';

const Main = () => {
  return (
    <Switch>
      <Route path="/consultations/:bookingId">
        <Booking />
      </Route>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Route path="/consultations" render={(props) => <List {...props} />} />
    </Switch>
  );
};

export default Main;
