import React from 'react';
import { Field, Form } from 'react-final-form';
import {
  addMonths,
  differenceInMinutes,
  endOfDay,
  isBefore,
  parseISO,
  startOfDay
} from 'date-fns';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import Dialog from '../Dialog';
import TextField from '../final-form/TextField';
import { v2, v2Base } from '../../axios';
import MyCheckbox from '../final-form/Checkbox';
import { formatDateTime, setTimeOfDate } from '../../utils/formats';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});

const AddAvailability = ({
  classes,
  snackbar,
  event,
  onAdd,
  onError,
  onCancel,
  user
}) => {
  const { t } = useTranslation(['agenda', 'validation', 'common']);
  const { duration_booking } = user.practician;

  const addAvailability = async (values) => {
    // Set date with new choosen time
    const endDate = setTimeOfDate(event.end, values.end_at);
    const startDate = setTimeOfDate(event.start, values.start_at);

    const start = formatDateTime(startDate);
    const end = formatDateTime(endDate);

    try {
      await v2.post('availabilities', {
        start,
        end
      });

      await onAdd();
      snackbar.open(t('agenda:agenda.availabilities.add.success'), 'success');
    } catch (error) {
      snackbar.open(t('agenda:agenda.availabilities.add.error'), 'error');
      onError();
    }
  };

  const addRecurrentAvailability = async (values) => {
    const begins_at = formatDateTime(startOfDay(event.start));
    const expires_at = formatDateTime(endOfDay(new Date(values.expires_at)));
    const day_of_week = event.start.getDay();

    const startDate = setTimeOfDate(event.end, values.start_at);
    const endDate = setTimeOfDate(event.end, values.end_at);
    const start = format(startDate, 'HH:mm');
    const end = format(endDate, 'HH:mm');

    try {
      await v2Base.post(`recurrent-availabilities`, {
        begins_at,
        expires_at,
        start,
        end,
        day_of_week,
        excluded_dates: []
      });

      snackbar.open(
        t('agenda:agenda.recurrentAvailabilities.add.success'),
        'success'
      );
      await onAdd(true);
    } catch (error) {
      snackbar.open(
        t('agenda:agenda.recurrentAvailabilities.add.error'),
        'error'
      );
      onError();
    }
  };

  const handleCreatedRecurrentAvailability = (values) => {
    if (values.recurrent) {
      return addRecurrentAvailability(values);
    }
    return addAvailability(values);
  };

  const validate = (values) => {
    const errors = {};

    const duration = differenceInMinutes(
      setTimeOfDate(event.end, values.end_at),
      setTimeOfDate(event.start, values.start_at)
    );

    if (values.recurrent) {
      if (!values.expires_at) {
        errors.expires_at = t('validation:validation.mandatory');
      }

      if (
        values.expires_at &&
        isBefore(parseISO(values.expires_at), new Date())
      ) {
        errors.expires_at = t('validation:validation.minDate');
      }
    }

    if (duration < duration_booking) {
      errors.end_at = t('validation:validation.bookingDuration', {
        duration: duration_booking
      });
    }

    return errors;
  };

  if (!event) return null;

  return (
    <Form
      onSubmit={handleCreatedRecurrentAvailability}
      validate={validate}
      initialValues={{
        expires_at: format(addMonths(event.start, 1), 'yyyy-MM-dd'),
        start_at: format(event.start, 'HH:mm'),
        end_at: format(event.end, 'HH:mm')
      }}
      render={({ handleSubmit, values }) => (
        <Dialog
          open
          title={t('agenda:agenda.availabilities.add.title')}
          description={t('agenda:agenda.availabilities.add.description', {
            start: t('common:common.date.datetime', {
              date: setTimeOfDate(event.start, values.start_at)
            }),
            end: t('common:common.date.datetime', {
              date: setTimeOfDate(event.end, values.end_at)
            })
          })}
          cancelText={t('agenda:agenda.availabilities.add.cancel')}
          confirmText={t('agenda:agenda.availabilities.add.confirm')}
          onCancel={onCancel}
          onAccept={handleSubmit}
        >
          <form
            onSubmit={handleSubmit}
            className={classes.container}
            id="recurrent-availability"
          >
            <Grid container>
              <Grid item xs={10}>
                <Box display="flex" justifyContent="space-between">
                  <Field
                    id="start_at"
                    name="start_at"
                    label="Start at"
                    component={TextField}
                    type="time"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <Field
                    id="end_at"
                    name="end_at"
                    label="End at"
                    component={TextField}
                    type="time"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="checkbox"
                  name="recurrent"
                  label={t(
                    'agenda:agenda.recurrentAvailabilities.dialog.recurrent'
                  )}
                  component={MyCheckbox}
                />
              </Grid>
              {values.recurrent && (
                <Grid item xs={12}>
                  <Field
                    id="expires_at"
                    name="expires_at"
                    label={t(
                      'agenda:agenda.recurrentAvailabilities.dialog.contentLabel'
                    )}
                    component={TextField}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Dialog>
      )}
    />
  );
};

export default withStyles(styles)(AddAvailability);
