import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import TextField from '../../../final-form/TextField';

import { styles } from '../styles';

const Personal = ({ classes }) => {
  const { t } = useTranslation('patients');

  return (
    <Paper variant="outlined" className={classes.block}>
      <Typography className={classes.subHeader}>
        {t('patients:patients.create.subHeaders.personal')}
      </Typography>

      <Field
        name="user.firstname"
        label={t('patients:patients.create.user.firstname')}
        component={TextField}
        fullWidth
      />
      <Box className={classes.row}>
        <Field
          name="user.lastname"
          label={t('patients:patients.create.user.lastname')}
          component={TextField}
          fullWidth
        />
        <Field
          name="user.birthday"
          label={t('patients:patients.create.user.birthday')}
          component={TextField}
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Box>
    </Paper>
  );
};

export default withStyles(styles)(Personal);
