import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import {
  isPrescriptorPractician,
  isSpecialistPractician
} from '../../../../utils/patients.utils';

const Decision = ({ patient }) => {
  const { t } = useTranslation(['patients', 'common']);
  const { treatment_plan: treatmentPlan } = patient;
  const decision = isSpecialistPractician(patient)
    ? treatmentPlan.decision_practician_specialist
    : treatmentPlan.decision_practician;

  const decision_at = isSpecialistPractician(patient)
    ? treatmentPlan.decision_practician_specialist_at
    : treatmentPlan.decision_practician_at;

  const isReviewingPractician =
    isPrescriptorPractician(patient) || isSpecialistPractician(patient);

  if (!isReviewingPractician) {
    return (
      <Typography>
        {decision
          ? t(
              'patients:patients.decideTreatment.decision.localPractician.accepted'
            )
          : t(
              'patients:patients.decideTreatment.decision.localPractician.refused'
            )}
      </Typography>
    );
  }

  return (
    <Typography>
      {decision
        ? t('patients:patients.decideTreatment.decision.accepted')
        : t('patients:patients.decideTreatment.decision.refused')}
      {t('common:common.date.detailed', {
        date: parseISO(decision_at)
      })}
    </Typography>
  );
};

export default Decision;
