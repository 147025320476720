import React from 'react';
import { Form, Field } from 'react-final-form';
import { Box, Button, Grid } from '@material-ui/core';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { isBefore, isValid, parse, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { v2 } from '../../axios';
import TextField from '../final-form/TextField';
import { ACCEPTED_PHONE_COUNTRIES } from './bookingRequests.utils';

const CreateBookingRequest = ({ onCancel, onSuccess }) => {
  const { t } = useTranslation('bookingRequests');

  const handleCreate = async (values) => {
    const parsedDate = parseISO(`${values.date} ${values.hour}`);

    try {
      await v2.post('booking-requests', {
        mobile: values.mobile,
        date: parsedDate
      });
      onSuccess();
      return undefined;
    } catch (error) {
      return error;
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.mobile) {
      errors.mobile = t(
        'bookingRequests:bookingRequests.add.validation.mandatory'
      );
    } else if (!isMobilePhone(values.mobile, ACCEPTED_PHONE_COUNTRIES)) {
      errors.mobile = t(
        'bookingRequests:bookingRequests.add.validation.incorrectMobile'
      );
    }

    if (!values.date) {
      errors.date = t(
        'bookingRequests:bookingRequests.add.validation.mandatory'
      );
    } else if (isBefore(parseISO(values.date), new Date())) {
      errors.date = t('bookingRequests:bookingRequests.add.validation.minDate');
    }
    if (!values.hour) {
      errors.hour = t(
        'bookingRequests:bookingRequests.add.validation.mandatory'
      );
    } else {
      const parsed = parse(values.hour, 'H:mm', new Date());
      if (!isValid(parsed)) {
        errors.date = t(
          'bookingRequests:bookingRequests.add.validation.incorrectTime'
        );
      }
    }

    return errors;
  };

  return (
    <Form
      onSubmit={handleCreate}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                fullWidth
                name="mobile"
                label={t('bookingRequests:bookingRequests.add.mobile')}
                component={TextField}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="date"
                label={t('bookingRequests:bookingRequests.add.date')}
                component={TextField}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="hour"
                label={t('bookingRequests:bookingRequests.add.time')}
                component={TextField}
                type="time"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Box mr={2} component="span">
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={onCancel}
                  >
                    {t('bookingRequests:bookingRequests.add.cancel')}
                  </Button>
                </Box>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                >
                  {t('bookingRequests:bookingRequests.add.confirm')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default CreateBookingRequest;
