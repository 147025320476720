import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import TreatmentInformations from './TreatmentInformations';
import Aligners from './aligners/Aligners';
import Aligner from './aligner/Aligner';

import { v2 } from '../../../../axios';
import TreatmentNotStarted from './TreatmentNotStarted';

const Treatment = ({ patient, fetchPatient }) => {
  const [remainingAligners, setRemainingAligners] = useState([]);

  useEffect(() => {
    const fetchRemainingAligners = async () => {
      try {
        const { data: fetchedRemainingAligners } = await v2.get(
          '/remaining-aligners',
          {
            params: {
              patient_id: patient.id
            }
          }
        );
        setRemainingAligners(fetchedRemainingAligners);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    fetchRemainingAligners();
  }, [patient.id]);

  return (
    <>
      {patient.treatment_started_at ? (
        <>
          <TreatmentInformations
            patient={patient}
            remainingAligners={remainingAligners}
          />
          <Switch>
            <Route
              path="/patient/:id/treatment/aligner/:serie"
              render={(props) => (
                <Aligner
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  fetchPatient={fetchPatient}
                  patient={patient}
                />
              )}
            />
            <Route
              render={(props) => (
                <Aligners
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  patient={patient}
                  remainingAligners={remainingAligners}
                />
              )}
            />
          </Switch>
        </>
      ) : (
        <TreatmentNotStarted patient={patient} />
      )}
    </>
  );
};

export default Treatment;
