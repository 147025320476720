import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

const RemainingAligners = ({ remainingAligners }) => {
  const { t } = useTranslation('common');
  return remainingAligners.map((a) => (
    <TableRow key={a.serie}>
      <TableCell component="th" scope="row">
        <Typography color="textSecondary">{a.serie}</Typography>
      </TableCell>
      <TableCell>
        <Typography color="textSecondary">
          {t('common:common.date.date', { date: parseISO(a.start) })}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="textSecondary">
          {t('common:common.date.date', { date: parseISO(a.end) })}
        </Typography>
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  ));
};

export default RemainingAligners;
