import React, { Fragment, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import ListSubheader from '@material-ui/core/ListSubheader';

import { withStyles } from '@material-ui/core/styles';

import { Box, ListItemAvatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { v2 } from '../../axios';
import Loadable from '../Loadable';

const styles = () => ({
  root: {
    height: 280,
    overflowY: 'auto'
  }
});

const Bookings = ({ classes }) => {
  const [bookings, setBookings] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation('dashboard');

  useEffect(() => {
    const fetchBookings = async () => {
      setIsFetching(true);
      try {
        const { data: fetchedBookings } = await v2.get('/bookings');
        setBookings(fetchedBookings);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchBookings();
  }, []);

  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Paper className={classes.root}>
        <List
          subheader={
            <ListSubheader component="div" color="primary">
              {t('dashboard:dashboard.bookings.title')}
            </ListSubheader>
          }
        >
          <Loadable loading={isFetching}>
            {bookings.length ? (
              <>
                {bookings.slice(0, 3).map((b) => (
                  <ListItem
                    button
                    component={Link}
                    to={`/consultations/${b.id}`}
                    key={b.id}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={b.patient.user.fullname}
                      secondary={`${t('common:common.date.datetime', {
                        date: parseISO(b.date)
                      })} - ${t(
                        `dashboard:dashboard.bookings.type.${b.type}`
                      )}`}
                    />
                  </ListItem>
                ))}
                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    align="center"
                    component={Link}
                    to="/consultations"
                  >
                    {t('dashboard:dashboard.bookings.seeAll')}
                  </Typography>
                </Box>
              </>
            ) : (
              <ListItem>
                <ListItemText
                  primary={t('dashboard:dashboard.bookings.empty')}
                />
              </ListItem>
            )}
          </Loadable>
        </List>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(Bookings);
