import React, { useState } from 'react';

import { IconButton, Snackbar } from '@material-ui/core';

import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

export const SnackbarContext = React.createContext();

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing()
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const SnackbarComponent = () => (
  <SnackbarContext.Consumer>
    {({ isOpen, message, close, variant }) => (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={close}
      >
        <MySnackbarContentWrapper
          onClose={close}
          variant={variant}
          message={message}
        />
      </Snackbar>
    )}
  </SnackbarContext.Consumer>
);

export const SnackbarProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('info');

  const open = (newMessage, newVariant = 'info') => {
    setMessage(newMessage);
    setVariant(newVariant);
    setIsOpen(true);
  };

  const close = () => {
    setMessage('');
    setIsOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        open,
        close,
        isOpen,
        message,
        variant
      }}
    >
      <SnackbarComponent />
      {children}
    </SnackbarContext.Provider>
  );
};

export const SnackbarConsumer = SnackbarContext.Consumer;
