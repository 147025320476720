export const PRACTICIAN_GENERALIST = 'practician-generalist';
export const PRACTICIAN_SPECIALIST = 'practician-specialist';
export const PRACTICIAN_MONITORING = 'practician-monitoring';
export const PRACTICIAN_DISTRIBUTOR = 'practician-distributor';

export const hasRole = (userRoles, roleName) =>
  userRoles.find((role) => role.name === roleName);

export const isLocalSpecialist = (userRoles) =>
  userRoles?.find(
    (role) =>
      role.name === PRACTICIAN_GENERALIST || role.name === PRACTICIAN_SPECIALIST
  );
