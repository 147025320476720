import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { parseISO, differenceInYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
import PatientPaid from '@material-ui/icons/CheckCircle';
import PatientNotPaid from '@material-ui/icons/WatchLater';
import green from '@material-ui/core/colors/green';

import { isLocalSpecialist } from '../../../utils/roles';
import { UserContext } from '../../../store/User';

import { Tag } from './Tags/Tag';
import { getFile } from '../../../api/files';

const styles = (theme) => ({
  avatar: {
    width: 90,
    height: 90,
    margin: 'auto'
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium
  },
  tag: {
    margin: theme.spacing(0, 1, 0.5, 0)
  },
  paymentStatus: {
    display: 'flex',
    alignItems: 'center'
  },
  paymentStatusText: {
    margin: theme.spacing(0, 0.5)
  },
  paymentStatusPaid: {
    color: green[500]
  },
  paymentStatusNotPaid: {
    color: theme.palette.grey.A200
  }
});

const Informations = ({ classes, patient, tags }) => {
  const { t } = useTranslation(['patients', 'common']);
  const [avatarPhoto, setAvatarPhoto] = useState(null);

  const user = useContext(UserContext);

  useEffect(() => {
    const fetchAvatarPhoto = async () => {
      try {
        const file = await getFile({
          receiver_type: 'patient',
          receiver_id: patient.id,
          type: 'treatment_photo',
          meta: {
            serie: 'check_up',
            angle: 'frontal_smiling'
          }
        });
        setAvatarPhoto(file);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetchAvatarPhoto();
  }, []);

  return (
    <Grid container alignItems="center">
      <Grid item md={12} sm={12} xs={12}>
        {avatarPhoto ? (
          <Avatar
            className={classes.avatar}
            alt={patient.user.fullname}
            src={avatarPhoto.url}
          />
        ) : (
          <Avatar className={classes.avatar} alt={patient.user.fullname}>
            <Typography variant="h3">
              {patient.user.lastname.charAt(0)}
              {patient.user.firstname.charAt(0)}
            </Typography>
          </Avatar>
        )}
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {patient.user.fullname}
          </Typography>
          <Typography variant="h6" align="center" color="error">
            {t(
              `patients:patients.view.informations.treatment.${patient.treatment_type}`
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography color="textSecondary" gutterBottom>
            {t('patients:patients.view.informations.personnal.title')}
          </Typography>
          <Typography>
            <strong>
              {t('patients:patients.view.informations.personnal.reference')}
            </strong>
            {patient.reference}
          </Typography>
          <Typography>
            <strong>
              {t('patients:patients.view.informations.personnal.dateOfBirth')}
            </strong>
            {t('common:common.date.date', {
              date: parseISO(patient.user.birthday)
            })}
          </Typography>
          <Typography>
            <strong>
              {t('patients:patients.view.informations.personnal.age')}
            </strong>
            {differenceInYears(new Date(), parseISO(patient.user.birthday))}
          </Typography>
          {isLocalSpecialist(user.roles) && (
            <Typography className={classes.paymentStatus}>
              <strong>
                {t(
                  'patients:patients.view.informations.personnal.paymentStatus'
                )}
              </strong>
              {patient.paid ? (
                <>
                  <span className={classes.paymentStatusText}>
                    {t(
                      'patients:patients.view.informations.personnal.paymentStatusPaid'
                    )}
                  </span>
                  <PatientPaid className={classes.paymentStatusPaid} />
                </>
              ) : (
                <>
                  <span className={classes.paymentStatusText}>
                    {t(
                      'patients:patients.view.informations.personnal.paymentStatusNotPaid'
                    )}
                  </span>
                  <PatientNotPaid className={classes.paymentStatusNotPaid} />
                </>
              )}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography color="textSecondary" gutterBottom>
            {t('patients:patients.view.informations.contact.title')}
          </Typography>
          <Typography>
            <strong>
              {t('patients:patients.view.informations.contact.email')}
            </strong>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${patient.user.email}`}
            >
              {patient.user.email}
            </a>
          </Typography>
          {patient.user.mobile && (
            <Typography gutterBottom>
              <strong>
                {t('patients:patients.view.informations.contact.mobile')}
              </strong>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`tel:${patient.user.mobile}`}
              >
                {patient.user.mobile}
              </a>
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography color="textSecondary" gutterBottom>
            {t('patients:patients.view.informations.tags.title')}
          </Typography>
          {tags.map((item) => (
            <Tag key={item.id} data={item} />
          ))}
          {tags.length === 0 && (
            <Typography>
              {t('patients:patients.view.informations.tags.empty')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Informations);
