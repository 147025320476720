import common from './common.json';
import validation from './validation.json';
import dashboard from './dashboard.json';
import patients from './patients.json';
import photos from './photos.json';
import treatmentPlan from './treatmentPlan.json';
import bookings from './bookings.json';
import bookingRequests from './bookingRequests.json';
import agenda from './agenda.json';
import comments from './comments.json';
import stripe from './stripe.json';
import pickups from './pickups.json';
import invoices from './invoices.json';

export default {
  common,
  validation,
  dashboard,
  patients,
  photos,
  treatmentPlan,
  bookings,
  bookingRequests,
  agenda,
  comments,
  stripe,
  pickups,
  invoices
};
