import React from 'react';

import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import ListBookingRequests from './ListBookingRequests';

import { SnackbarConsumer } from '../Snackbar';

const BookingRequests = () => {
  const { t } = useTranslation('bookingRequests');
  return (
    <Paper>
      <Header
        breadcrumbs={[
          {
            name: t('bookingRequests:bookingRequests.list.title'),
            url: '/reservations'
          }
        ]}
      />
      <SnackbarConsumer>
        {(snackbar) => <ListBookingRequests snackbar={snackbar} />}
      </SnackbarConsumer>
    </Paper>
  );
};

export default BookingRequests;
