import React from 'react';
import { Box, Paper } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';

import { PATIENT_TABS } from '../../../constants/patients';

const PatientTabs = ({ activeTab, onChange, labReport, patient }) => {
  const { t } = useTranslation('patients');
  return (
    <Box mt={2}>
      <Paper>
        <Box p={2}>
          <Tabs
            value={activeTab}
            onChange={onChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {!!patient.treatment_started_at && (
              <Tab
                value={PATIENT_TABS.TREATMENT}
                label={t('patients:patients.decideTreatment.tabs.treatment')}
              />
            )}
            {labReport && (
              <Tab
                value={PATIENT_TABS.LAB_REPORT}
                label={t('patients:patients.decideTreatment.tabs.labReport')}
              />
            )}
            <Tab
              value={PATIENT_TABS.PHOTOS}
              label={t('patients:patients.decideTreatment.tabs.photos')}
            />
            <Tab
              value={PATIENT_TABS.COMMENTS}
              label={t('patients:patients.decideTreatment.tabs.comments')}
            />
            <Tab
              value={PATIENT_TABS.MEDICAL_INFORMATIONS}
              label={t(
                'patients:patients.decideTreatment.tabs.medicalInformations'
              )}
            />
            <Tab
              value={PATIENT_TABS.TREATMENT_PLAN}
              label={t('patients:patients.decideTreatment.tabs.treatmentPlan')}
            />
            <Tab
              value={PATIENT_TABS.SETTINGS}
              label={t('patients:patients.decideTreatment.tabs.settings')}
            />
          </Tabs>
        </Box>
      </Paper>
    </Box>
  );
};

export default PatientTabs;
