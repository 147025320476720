import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import { Button, Fab, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CreateBookingRequest from './CreateBookingRequest';

const DialogCreateBookingRequest = ({ onSuccess, type }) => {
  const { t } = useTranslation('bookingRequests');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  return (
    <div>
      {type === 'fab' ? (
        <Tooltip
          title={t('bookingRequests:bookingRequests.add.tooltip')}
          aria-label="add"
        >
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      ) : (
        <Button color="primary" variant="outlined" onClick={handleClickOpen}>
          {t('bookingRequests:bookingRequests.add.title')}
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('bookingRequests:bookingRequests.add.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('bookingRequests:bookingRequests.add.description')}
          </DialogContentText>
          <CreateBookingRequest
            onCancel={handleClose}
            onSuccess={handleSuccess}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogCreateBookingRequest;
