import React from 'react';
import Button from '@material-ui/core/Button';
import MUIDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Dialog = ({
  open,
  onCancel,
  title,
  description,
  onAccept,
  cancelText,
  confirmText,
  disabledConfirm,
  children
}) => (
  <MUIDialog
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {description && (
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      )}
      {children}
    </DialogContent>
    <DialogActions>
      {onCancel && (
        <Button onClick={onCancel} color="secondary">
          {cancelText || 'Annuler'}
        </Button>
      )}
      {onAccept && (
        <Button
          onClick={onAccept}
          color="primary"
          autoFocus
          disabled={disabledConfirm}
        >
          {confirmText || 'Confirmer'}
        </Button>
      )}
    </DialogActions>
  </MUIDialog>
);

export default Dialog;
