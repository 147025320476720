import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import {
  healthQuestionsByCategories,
  consultationQuestions
} from '../../../questions';

const styles = (theme) => ({
  avatar: {
    width: 90,
    height: 90,
    margin: 'auto'
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium
  }
});

const Questions = ({ patient }) => {
  const { t, i18n } = useTranslation('patients');
  const { language } = i18n;
  return (
    <Box mt={2} p={2}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {t(
              'patients:patients.decideTreatment.medicalInformations.localPractician.title'
            )}
          </Typography>
          <br />
        </Grid>
        {consultationQuestions.map((question) => {
          if (!patient.questions[question.name]) {
            return null;
          }

          return (
            <Grid item xs={12} sm={6} key={question.name}>
              <Typography color="textSecondary">
                {question[language]}
              </Typography>
              <Typography
                color={
                  question.expectedAnswer !== patient.questions[question.name]
                    ? 'error'
                    : 'initial'
                }
              >
                {patient.questions[question.name] === 'yes' &&
                  t(
                    'patients:patients.decideTreatment.medicalInformations.answers.yes'
                  )}
                {patient.questions[question.name] === 'no' &&
                  t(
                    'patients:patients.decideTreatment.medicalInformations.answers.no'
                  )}
              </Typography>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <br />
          <Typography variant="h6">
            {t(
              'patients:patients.decideTreatment.medicalInformations.patient.title'
            )}
          </Typography>
        </Grid>
        {healthQuestionsByCategories.map((category) => (
          <Fragment key={category.name}>
            <Grid item xs={12}>
              <br />
              <Typography variant="subtitle1">{category[language]}</Typography>
              <br />
            </Grid>
            {category.questions.map((question) => {
              return (
                <Grid key={question.name} item xs={12} sm={6}>
                  <Typography color="textSecondary">
                    {question[language]}
                  </Typography>
                  <Typography
                    color={
                      question.expectedAnswer !==
                      patient.health_questions[question.name]
                        ? 'error'
                        : 'initial'
                    }
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {patient.health_questions[question.name] === 'yes'
                      ? t(
                          'patients:patients.decideTreatment.medicalInformations.answers.yes'
                        )
                      : patient.health_questions[question.name] === 'no'
                      ? t(
                          'patients:patients.decideTreatment.medicalInformations.answers.no'
                        )
                      : patient.health_questions[question.textfieldName] ||
                        t(
                          'patients:patients.decideTreatment.medicalInformations.answers.noAnswer'
                        )}
                  </Typography>
                </Grid>
              );
            })}
          </Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(Questions);
