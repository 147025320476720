import React, { useState } from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { v2 } from '../../axios';
import MoreMenu from '../MoreMenu';

const Charge = ({ charge }) => {
  const [isFetchingPlatform, setIsFetchingPlatform] = useState(false);
  const [isFetchingPatient, setIsFetchingPatient] = useState(false);
  const { t } = useTranslation('common');

  const downloadInvoicePlatform = async () => {
    setIsFetchingPlatform(true);

    const { data: content } = await v2.get(
      `charge/${charge.id}/invoice-platform`,
      {
        responseType: 'arraybuffer'
      }
    );

    const invoice = new File(
      [content],
      `facture-platforme-${
        charge.metadata.service === 'booking' ? 'rdv' : 'traitement'
      }-${charge.patient.user.firstname.toLowerCase()}-${charge.patient.user.lastname.toLowerCase()}.pdf`,
      {
        type: `application/pdf`
      }
    );

    FileSaver.saveAs(invoice);

    setIsFetchingPlatform(false);
  };

  const downloadInvoicePatient = async () => {
    setIsFetchingPatient(true);

    const { data: content } = await v2.get(
      `charge/${charge.id}/invoice-patient`,
      {
        responseType: 'arraybuffer'
      }
    );

    const invoice = new File(
      [content],
      `facture-patient-${
        charge.metadata.service === 'booking' ? 'rdv' : 'traitement'
      }-${charge.patient.user.firstname.toLowerCase()}-${charge.patient.user.lastname.toLowerCase()}.pdf`,
      {
        type: `application/pdf`
      }
    );

    FileSaver.saveAs(invoice);
    setIsFetchingPatient(false);
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Link to={`/patient/${charge.patient.id}`}>
          {charge.patient.user.fullname}
        </Link>
      </TableCell>
      <TableCell>
        {t('common:common.date.date', { date: parseISO(charge.created_at) })}
      </TableCell>
      <TableCell>
        {charge.metadata.service === 'booking'
          ? 'Prise de rendez-vous'
          : 'Traitement'}
      </TableCell>
      <TableCell>{charge.amount / 100}€</TableCell>
      <TableCell>{charge.amount_net / 100}€</TableCell>
      <TableCell>{charge.application_fee.amount / 100}€</TableCell>
      <TableCell>
        <MoreMenu
          options={[
            {
              label: 'Télécharger facture patient',
              onClick: downloadInvoicePatient,
              disabled: isFetchingPatient,
              loading: isFetchingPatient
            },
            {
              label: 'Télécharger facture plateforme',
              onClick: downloadInvoicePlatform,
              disabled: isFetchingPlatform,
              loading: isFetchingPlatform
            }
          ]}
        />
      </TableCell>
    </TableRow>
  );
};

export default Charge;
