import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

import Layout from './components/Layout';

import './styles/index.css';

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Router>
      <Layout />
    </Router>
  </MuiThemeProvider>
);

export default App;
