const RedirectToAuth = () => {
  const encodedURI = encodeURI(window.location.href);

  window.location.replace(
    `${process.env.REACT_APP_AUTH_URL}/login?redirect=${encodedURI}`
  );

  return null;
};

export default RedirectToAuth;
