import React from 'react';
import { useTranslation } from 'react-i18next';
import { v2 } from '../../axios';
import Dialog from '../Dialog';

export const DeleteAvailability = ({ event, snackbar, onDelete, onCancel }) => {
  const { t } = useTranslation(['agenda', 'common']);
  const deleteAvailability = async () => {
    try {
      await v2.delete(`availabilities/${event.id}`);

      snackbar.open(
        t('agenda:agenda.availabilities.delete.success'),
        'success'
      );
    } catch (error) {
      snackbar.open(t('agenda:agenda.availabilities.delete.error'), 'error');
    } finally {
      await onDelete();
    }
  };

  if (!event) return null;
  return (
    <Dialog
      open
      title={t('agenda:agenda.availabilities.delete.title')}
      description={t('agenda:agenda.availabilities.delete.description', {
        date: t('common:common.date.date', {
          date: event.start
        }),
        startTime: t('common:common.date.time', {
          date: event.start
        }),
        endTime: t('common:common.date.time', {
          date: event.end
        })
      })}
      onCancel={onCancel}
      onAccept={deleteAvailability}
      cancelText={t('agenda:agenda.availabilities.delete.cancel')}
      confirmText={t('agenda:agenda.availabilities.delete.confirm')}
    />
  );
};
