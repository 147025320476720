import parseISO from 'date-fns/parseISO';

export const DEFAULT_MIN_TIME = '16:00';
export const DEFAULT_MAX_TIME = '18:00';
export const MINIMUM_INTERVAL = 2;
export const MIN_NB_PACKAGES = 1;
export const MAX_NB_PACKAGES = 5;

export const formatPickupDates = (dates, t, i18n) =>
  dates.map((date) => {
    const day = t('common:common.date.day', { date: parseISO(date) });
    const formattedDate = new Date(date).toLocaleDateString(i18n.language, {
      month: 'long',
      day: 'numeric'
    });
    return {
      label: `${day} - ${formattedDate}`,
      value: date
    };
  });

export const formatPickupHours = (hours) =>
  hours.map((hour) => ({
    label: hour,
    value: hour
  }));

export const getHoursFromTime = (time) => {
  const [hour] = time.split(':');

  return parseInt(hour, 10);
};
