import React, { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';

const BookingInformations = ({ booking }) => {
  const { t } = useTranslation(['bookings', 'common']);
  return (
    <>
      <Grid item md={3} sm={4} xs={6}>
        <Typography color="textSecondary" gutterBottom>
          {t('bookings:bookings.viewBooking.details.date')}
        </Typography>
        <Typography>
          {t('common:common.date.detailed', {
            date: parseISO(booking.date)
          })}
        </Typography>
      </Grid>

      <Grid item md={3} sm={4} xs={6}>
        <Typography color="textSecondary" gutterBottom>
          {t('bookings:bookings.viewBooking.details.time')}
        </Typography>
        <Typography>
          {t('common:common.date.time', {
            date: parseISO(booking.date)
          })}
        </Typography>
      </Grid>

      <Grid item md={3} sm={4} xs={6}>
        <Typography color="textSecondary" gutterBottom>
          {t('bookings:bookings.viewBooking.details.type')}
        </Typography>
        <Typography>{t(`bookings:bookings.type.${booking.type}`)}</Typography>
      </Grid>

      <Grid item md={3} sm={4} xs={6}>
        <Typography color="textSecondary" gutterBottom>
          {t('bookings:bookings.viewBooking.details.price')}
        </Typography>
        <Typography>{booking.formatted_price}</Typography>
      </Grid>
    </>
  );
};

export default BookingInformations;
