import React, { useState } from 'react';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Box, Link, ListItemText, Typography } from '@material-ui/core';
import { getAuthor } from '../../../../utils/comments.utils';
import { api } from '../../../../axios';

const Comment = ({ comment }) => {
  const { t } = useTranslation('common');
  const [translation, setTranslation] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);

  const translate = async (e) => {
    e.preventDefault();
    setIsTranslating(true);
    try {
      const { data: translatedContent } = await api.post('/translate', {
        text: comment.content
      });
      setTranslation(translatedContent);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsTranslating(false);
    }
  };

  const time = t('common:common.date.datetime', {
    date: parseISO(comment.created_at)
  });

  return (
    <ListItemText
      primary={
        <>
          {comment.content}
          <Typography color="textSecondary">{translation}</Typography>
        </>
      }
      secondary={
        <Typography color="textSecondary" variant="caption">
          {`${getAuthor(comment)} - ${time}`}
          {!translation && (
            <Box component="span" ml={1}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link href="#" onClick={translate}>
                {isTranslating
                  ? t('common:common.translate.translating')
                  : t('common:common.translate.cta')}
              </Link>
            </Box>
          )}
        </Typography>
      }
    />
  );
};

export default Comment;
