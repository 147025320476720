import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SnackbarConsumer } from '../Snackbar';

import List from './listPatients/ListPatients';
import View from './view/ViewPatient';
import CreatePatient from './create/CreatePatient';

const Patients = () => (
  <SnackbarConsumer>
    {(snackbar) => (
      <Switch>
        <Route exact path="/patients/create" component={CreatePatient} />
        <Route exact path="/patients/:status?" component={List} />
        <Route
          path="/patient/:id/:section?"
          // eslint-disable-next-line react/jsx-props-no-spreading
          render={(props) => <View {...props} snackbar={snackbar} />}
        />
      </Switch>
    )}
  </SnackbarConsumer>
);

export default Patients;
