import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from './Dialog';

class DialogWithButton extends Component {
  state = {
    open: false,
    isAccepting: false
  };

  setOpen = (open) => {
    this.setState({ open });
  };

  handleOpen = () => {
    this.setOpen(true);
  };

  handleClose = () => {
    this.setOpen(false);
  };

  handleAccept = async () => {
    const { onAccept } = this.props;
    try {
      this.setState({ isAccepting: true });
      await onAccept();
    } finally {
      this.setState({ isAccepting: false });
      this.setOpen(false);
    }
  };

  render() {
    const {
      buttonText,
      variant,
      color,
      title,
      description,
      cancelText,
      confirmText,
      disabled,
      buttonProps
    } = this.props;
    const { open, isAccepting } = this.state;

    return (
      <>
        <Button
          onClick={this.handleOpen}
          variant={variant || 'contained'}
          color={color || 'primary'}
          disabled={disabled}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
        >
          {buttonText}
        </Button>
        <Dialog
          open={open}
          onCancel={this.handleClose}
          onAccept={this.handleAccept}
          title={title}
          description={description}
          cancelText={cancelText}
          confirmText={confirmText}
          disabledConfirm={isAccepting}
        />
      </>
    );
  }
}

export default DialogWithButton;
