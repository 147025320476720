import client from 'axios';

export const v2 = client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/practician`,
  withCredentials: true
});

export const v2Base = client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2`,
  withCredentials: true
});

export const api = client.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

/**
 * Used for S3 request to avoid cors issues
 */
export const axiosWithoutCredentials = client.create();
