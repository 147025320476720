export const COUNTRIES = {
  FR: 'FR',
  ES: 'ES',
  BE: 'BE'
};

export const getPhoneCountryCode = (country) => {
  switch (country) {
    case COUNTRIES.BE:
      return '32';
    case COUNTRIES.FR:
      return '33';
    case COUNTRIES.ES:
      return '34';
    default:
      return '';
  }
};

const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

const autocompleteOptions = {
  types: ['geocode'],
  componentRestrictions: {
    country: [COUNTRIES.FR, COUNTRIES.BE, COUNTRIES.ES]
  }
};

export const loadGoogleMaps = (onLoad) => {
  if (!window.google?.maps?.places?.Autocomplete) {
    const script = document.createElement('script');
    script.src = GOOGLE_MAPS_API_URL;
    script.onload = onLoad;

    document.head.appendChild(script);
  } else {
    onLoad();
  }
};

export const createAutocompleteInstance = (input, onAutocomplete) => {
  const autoCompleteInstance = new window.google.maps.places.Autocomplete(
    input,
    autocompleteOptions
  );

  const handleAutocompletePlaceChanged = () => {
    const { address_components = [] } = autoCompleteInstance.getPlace();

    const { country, city, postal_code, address } = address_components.reduce(
      (acc, { types, short_name }) => {
        if (types.includes('country')) {
          acc.country = short_name;
        }

        if (types.includes('locality')) {
          acc.city = short_name;
        }

        if (types.includes('postal_code')) {
          acc.postal_code = short_name;
        }

        if (types.includes('street_number')) {
          acc.address.push(short_name);
        }

        if (types.includes('route')) {
          acc.address.push(short_name);
        }

        return acc;
      },
      { country: '', city: '', postal_code: '', address: [] }
    );

    onAutocomplete({
      country,
      city,
      postal_code,
      address: address.join(' ')
    });
  };

  autoCompleteInstance.setFields(['address_components', 'formatted_address']);

  const listener = autoCompleteInstance.addListener(
    'place_changed',
    handleAutocompletePlaceChanged
  );

  const cleanup = () => {
    window.google.maps.event.removeListener(listener);
    window.google.maps.event.clearInstanceListeners(autoCompleteInstance);
  };

  return cleanup;
};
