import React, { useEffect, useState } from 'react';

import {
  Grid as GridDX,
  Toolbar,
  SearchPanel,
  Table,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui';

import { withStyles } from '@material-ui/core/styles';
import { Box, Fab, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IntegratedFiltering, SearchState } from '@devexpress/dx-react-grid';
import subDays from 'date-fns/subDays';
import parseISO from 'date-fns/parseISO';
import isToday from 'date-fns/isToday';
import isPast from 'date-fns/isPast';
import { GetApp, Delete } from '@material-ui/icons';
import DialogCreatePickup from './DialogCreatePickup';
import Loadable from '../Loadable';
import Dialog from '../Dialog';
import { v2Base } from '../../axios';

const DAYS_TO_FETCH = 10;

const styles = (theme) => ({
  deleteBtn: {
    marginLeft: theme.spacing(2)
  }
});

const ListPickups = ({ classes }) => {
  const { t } = useTranslation(['pickups', 'common']);
  const [pickups, setPickups] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isCancelling, setCancelling] = useState(false);
  const [pickupToCancel, setPickupToCancel] = useState(null);

  const fetchPickups = async () => {
    setIsFetching(true);
    try {
      const { data } = await v2Base.get('/pickups', {
        params: {
          min_date: subDays(new Date(), DAYS_TO_FETCH)
        }
      });
      setPickups(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const checkIfPickupCanBeCanceled = ({ min_date, status }) => {
    const minDate = parseISO(min_date);
    return status !== 'canceled' && !isToday(minDate) && !isPast(minDate);
  };

  const closeModal = () => {
    setPickupToCancel(null);
  };

  const handleCancelPickup = async () => {
    try {
      setCancelling(true);
      await v2Base.put(`/pickups/${pickupToCancel}/cancel`);
      fetchPickups();
      closeModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setCancelling(false);
    }
  };

  useEffect(() => {
    fetchPickups();
  }, []);

  return (
    <Loadable loading={isFetching}>
      <Box p={2} display="flex" justifyContent="flex-end">
        <DialogCreatePickup onSuccess={fetchPickups} type="fab" />
      </Box>
      <GridDX
        rows={pickups}
        columns={[
          {
            name: 'Reference',
            title: t('pickups:pickups.list.table.reference'),
            getCellValue: (pickup) => pickup.tracking_reference
          },
          {
            name: 'date',
            title: t('pickups:pickups.list.table.date'),
            getCellValue: (pickup) =>
              `${t(`common:common.date.datetime`, {
                date: parseISO(pickup.min_date)
              })} - ${t(`common:common.date.time`, {
                date: parseISO(pickup.max_date)
              })}`
          },
          {
            name: 'nbPackages',
            title: t('pickups:pickups.list.table.nbPackages'),
            getCellValue: (pickup) => pickup.data.packages.length
          },
          {
            name: 'status',
            title: t('pickups:pickups.list.table.status'),
            getCellValue: (pickup) =>
              t(`pickups:pickups.status.${pickup.status}`)
          },

          {
            name: 'label',
            title: t('pickups:pickups.list.table.label'),
            getCellValue: ({
              data,
              min_date,
              tracking_reference,
              id,
              status
            }) => (
              <Box>
                <Tooltip title={t('pickups:pickups.list.table.download')}>
                  <Fab
                    size="small"
                    aria-label="download"
                    href={`data:application/octet-stream;base64,${data.documents[0].content}`}
                    download={`${t('common:common.date.date', {
                      date: parseISO(min_date)
                    })}-${tracking_reference}.pdf`}
                  >
                    <GetApp />
                  </Fab>
                </Tooltip>
                {checkIfPickupCanBeCanceled({ min_date, status }) && (
                  <Tooltip title={t('pickups:pickups.list.table.cancel')}>
                    <Fab
                      size="small"
                      aria-label="cancel"
                      color="secondary"
                      onClick={() => setPickupToCancel(id)}
                      className={classes.deleteBtn}
                      disabled={isCancelling}
                    >
                      <Delete />
                    </Fab>
                  </Tooltip>
                )}
              </Box>
            )
          }
        ]}
      >
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <Toolbar />
        <SearchPanel />
        <Table />
        <TableHeaderRow />
      </GridDX>
      <Dialog
        open={!!pickupToCancel}
        onAccept={handleCancelPickup}
        onCancel={closeModal}
        title={t('pickups:pickups.list.modal.title')}
        description={t('pickups:pickups.list.modal.description')}
        confirmText={t('pickups:pickups.list.modal.confirm')}
        cancelText={t('pickups:pickups.list.modal.close')}
      />
    </Loadable>
  );
};

export default withStyles(styles)(ListPickups);
