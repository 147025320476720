import qs from 'query-string';

export const STATUS_CONFIRMED = 'confirmed';
export const STATUS_DONE = 'done';

export const getInitialValues = ({ patient, booking }) => {
  const { questions } = patient;
  return {
    decision_practician:
      patient.check_up_decision_practician_at === null
        ? true
        : patient.check_up_decision_practician,
    is_digital_impression:
      booking.status === 'confirmed' ? false : booking.is_digital_impression,
    has_xray: booking.status === 'confirmed' ? false : booking.has_xray,
    requested_xray: true,
    lower_retainer: patient.lower_retainer,
    upper_retainer: patient.upper_retainer,

    whitening:
      questions && questions.whitening ? questions.whitening === 'yes' : true,
    descaling:
      questions && questions.descaling ? questions.descaling === 'yes' : false,
    decay: questions && questions.decay ? questions.decay === 'yes' : false,
    fillings:
      questions && questions.fillings ? questions.fillings === 'yes' : false,
    loose_teeth:
      questions && questions.loose_teeth
        ? questions.loose_teeth === 'yes'
        : false,
    roots: questions && questions.roots ? questions.roots === 'yes' : false,
    non_erupted:
      questions && questions.non_erupted
        ? questions.non_erupted === 'yes'
        : false,
    gum: questions && questions.gum ? questions.gum === 'yes' : false,
    cancer: questions && questions.cancer ? questions.cancer === 'yes' : false,
    premolars:
      questions && questions.premolars ? questions.premolars === 'yes' : false,
    premolars_details:
      questions && questions.premolars_details
        ? questions.premolars_details
        : '',
    has_other: questions && questions.other ? !!questions.other : false,
    other: questions && questions.other ? questions.other : '',
    reason_for_refusal: patient.reason_for_refusal
  };
};

export const redirectToImpressionDocument = (impression, user) => {
  const { patient } = impression;

  const query = qs.stringify({
    id: impression.reference,
    patient: `${patient.user.firstname} ${patient.user.lastname.charAt(0)}.`,
    patientReference: patient.reference,
    practitioner: user.fullname,
    laboratoryScannerReference: impression.laboratory_reference,
    date: impression.created_at,
    print: true
  });

  return window.open(`https://utils.joovence.dev/pdf/scan?${query}`, '_blank');
};
