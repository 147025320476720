import React from 'react';
import { Box } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import useLabReport from './useLabReport';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PreviewLabReport = ({ patientId }) => {
  const { labReport } = useLabReport(patientId);

  if (!labReport) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="center">
      <Document file={labReport.url}>
        <Page scale={1.5} pageNumber={1} />
      </Document>
    </Box>
  );
};

export default PreviewLabReport;
