import { Grid } from '@material-ui/core';
import format from 'date-fns/format';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { v2Base } from '../../axios';
import Dialog from '../Dialog';
import MyCheckbox from '../final-form/Checkbox';
import { occurenceIsExcluded } from './helpers';

export const DeleteRecurrentAvailability = ({
  event,
  snackbar,
  onDelete,
  onCancel
}) => {
  const { t } = useTranslation(['agenda', 'common']);
  const isExcluded = occurenceIsExcluded(event.data, event.start);
  const deleteRecurrentAvailability = async () => {
    try {
      await v2Base.delete(`recurrent-availabilities/${event.id}`);

      snackbar.open(
        t('agenda:agenda.recurrentAvailabilities.delete.success'),
        'success'
      );
    } catch (error) {
      snackbar.open(
        t('agenda:agenda.recurrentAvailabilities.delete.error'),
        'error'
      );
    } finally {
      await onDelete();
    }
  };

  const addOccurrenceToExcludedDates = (chosenDate, excludedDates) => {
    excludedDates.push(chosenDate);
    return excludedDates;
  };

  const removeOccurrenceFromExcludedDates = (chosenDate, excludedDates) => {
    const index = excludedDates.indexOf(chosenDate);
    if (index > -1) {
      excludedDates.splice(index, 1);
    }
    return excludedDates;
  };

  const handleOccurrence = async (values) => {
    const occurenceDate = format(event.start, 'yyyy-MM-dd');
    const { excluded_dates: currentExcludedDates } = event.data;

    let newExcludedDates;
    if (isExcluded) {
      newExcludedDates = removeOccurrenceFromExcludedDates(
        occurenceDate,
        currentExcludedDates
      );
    } else {
      newExcludedDates = addOccurrenceToExcludedDates(
        occurenceDate,
        currentExcludedDates
      );
    }

    try {
      await v2Base.put(`recurrent-availabilities/${event.id}`, {
        excluded_dates: newExcludedDates
      });

      if (values.occurence_only) {
        snackbar.open(
          t('agenda:agenda.recurrentAvailabilities.deleteOccurrence.success'),
          'success'
        );
      } else {
        snackbar.open(
          t('agenda:agenda.recurrentAvailabilities.restoreOccurrence.success'),
          'success'
        );
      }
    } catch (error) {
      if (values.occurence_only) {
        snackbar.open(
          t('agenda:agenda.recurrentAvailabilities.deleteOccurrence.error'),
          'error'
        );
      } else {
        snackbar.open(
          t('agenda:agenda.recurrentAvailabilities.restoreOccurrence.error'),
          'error'
        );
      }
    } finally {
      await onDelete();
    }
  };

  const handleRecurrentAvailability = (values) => {
    if (values.occurence_only || isExcluded) {
      handleOccurrence(values);
    } else {
      deleteRecurrentAvailability();
    }
  };

  if (!event) return null;
  return (
    <Form
      onSubmit={handleRecurrentAvailability}
      render={({ handleSubmit }) => (
        <Dialog
          open
          title={
            isExcluded
              ? t(
                  'agenda:agenda.recurrentAvailabilities.restoreOccurrence.title'
                )
              : t('agenda:agenda.recurrentAvailabilities.delete.title')
          }
          description={t(
            'agenda:agenda.recurrentAvailabilities.restoreOccurrence.description',
            {
              date: t('common:common.date.date', {
                date: event.start
              }),
              startTime: t('common:common.date.time', {
                date: event.start
              }),
              endTime: t('common:common.date.time', {
                date: event.end
              })
            }
          )}
          onCancel={onCancel}
          onAccept={handleSubmit}
          cancelText={t(
            'agenda:agenda.recurrentAvailabilities.restoreOccurrence.cancel'
          )}
          confirmText={
            isExcluded
              ? t(
                  'agenda:agenda.recurrentAvailabilities.restoreOccurrence.confirm'
                )
              : t('agenda:agenda.recurrentAvailabilities.delete.confirm')
          }
        >
          <form onSubmit={handleSubmit} id="recurrent-availability-delete">
            <Grid container>
              {!isExcluded && (
                <Grid item xs={12}>
                  <Field
                    type="checkbox"
                    name="occurence_only"
                    label={t(
                      'agenda:agenda.recurrentAvailabilities.deleteOccurrence.checkBoxLabel'
                    )}
                    component={MyCheckbox}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Dialog>
      )}
    />
  );
};
