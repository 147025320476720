import React, { useState, useEffect, useCallback } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ListComments from './ListComments';
import { v2 } from '../../../../axios';
import AddComment from './AddComment';
import Loadable from '../../../Loadable';

const Comments = ({ patient }) => {
  const [comments, setComments] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation('comments');

  const fetchComments = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data: fetchedComments } = await v2.get(`comments`, {
        params: {
          patient_id: patient.id
        }
      });

      setComments(fetchedComments);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }, [patient.id]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments, patient.id]);

  return (
    <Box mt={2}>
      <Paper>
        <Box p={2}>
          <Typography variant="h6" gutterBottom>
            {t('comments:comments.title')}
          </Typography>
          <AddComment patient={patient} onAdd={fetchComments} />
          <Loadable loading={isFetching}>
            {comments.length ? (
              <ListComments comments={comments} />
            ) : (
              <Box mt={2}>
                <Typography align="center" gutterBottom>
                  {t('comments:comments.list.noData')}
                </Typography>
              </Box>
            )}
          </Loadable>
        </Box>
      </Paper>
    </Box>
  );
};

export default Comments;
