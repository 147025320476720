import React, { useRef } from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing()
  }
});

const MySelect = ({
  input: { name, value, onChange },
  meta,
  label,
  formControlProps,
  classes,
  onChange: onChangeField,
  options,
  showEmpty,
  inputLabelProps,
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const inputRef = useRef(null);

  return (
    <FormControl
      variant="outlined"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formControlProps}
      className={classes.formControl}
      error={showError}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <InputLabel ref={inputRef} htmlFor={name} {...inputLabelProps}>
        {label}
      </InputLabel>
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        native
        onChange={(e) => {
          onChange(e);
          if (onChangeField) {
            onChangeField(e);
          }
        }}
        value={value}
        label={label}
      >
        {showEmpty && <option value="" disabled aria-label="empty option" />}
        {options.map((item) => (
          <option key={`${name}-${item.value}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
      {showError && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(MySelect);
