import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import LoaderLogo from './LoaderLogo';

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(to top right, #5CAAAE 0%, #AAD9DC 100%)'
  },
  spinner: {
    margin: theme.spacing(3)
  }
});

const LoadingPage = ({ classes }) => (
  <div className={classes.root}>
    <LoaderLogo />
  </div>
);

export default withStyles(styles, { withTheme: true })(LoadingPage);
