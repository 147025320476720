import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const AsyncActionButton = ({
  children,
  onClick,
  progressProps,
  ...buttonProps
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleClick = async (event) => {
    if (!onClick) {
      return;
    }
    setIsFetching(true);
    await onClick(event);
    setIsFetching(false);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button disabled={isFetching} {...buttonProps} onClick={handleClick}>
      {isFetching ? (
        <CircularProgress
          size={20}
          style={{
            color: 'white'
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...progressProps}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default AsyncActionButton;
