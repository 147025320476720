const initializeIntercom = (user = null) => {
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_ID,
    type: 'practician',
    name: `Dr. ${user.fullname}`,
    ...user
  });
};

export default initializeIntercom;
