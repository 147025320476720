import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  progress: {
    marginBottom: theme.spacing(2)
  }
});

const Loadable = ({ loading, children, classes, message, size, noMessage }) => {
  const { t } = useTranslation('common');
  if (!loading) {
    return children;
  }

  if (noMessage) {
    return <CircularProgress size={size || 40} color="secondary" />;
  }

  return (
    <div className={classes.root}>
      <CircularProgress
        size={size || 40}
        className={classes.progress}
        color="secondary"
      />
      <Typography>{message || t('common:common.loading')}</Typography>
    </div>
  );
};

export default withStyles(styles)(Loadable);
