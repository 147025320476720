import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  sidebarWidth: 240,
  palette: {
    success: {
      main: '#4CAF50'
    }
  },
  overrides: {
    MuiAppBar: {
      // Name of the component ⚛️ / style sheet
      colorPrimary: {
        // Name of the rule
        background: 'linear-gradient(to right, #5CAAAE 0%, #AAD9DC 100%)' // Some CSS
      }
    }
  }
});
