import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, withStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { v2Base } from '../../axios';

import Loadable from '../Loadable';

import { redirectToStripeOAuthConnect } from './helpers';
import { styles } from './styles';

const Stripe = ({
  user: {
    practician: { id: practicianId, stripe_user_id }
  },
  query: { code },
  fetchUser,
  classes
}) => {
  const { t } = useTranslation('stripe');

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const shouldRedirect = !stripe_user_id && !code;
  const shouldSendCode = !stripe_user_id && typeof code === 'string';

  useEffect(() => {
    if (shouldRedirect) {
      redirectToStripeOAuthConnect();
    }
  }, [shouldRedirect]);

  useEffect(() => {
    if (shouldSendCode) {
      const connectStripeOAuth = async () => {
        try {
          setLoading(true);

          const { data: success } = await v2Base.put(
            `/practicians/${practicianId}/link-stripe`,
            { code }
          );

          if (success) {
            await fetchUser();
          } else {
            setError(t('stripe:stripe.error'));
          }
        } catch (err) {
          setError(err?.response?.data?.message || err.message);
        } finally {
          setLoading(false);
        }
      };

      connectStripeOAuth();
    }
  }, [shouldSendCode, code]);

  return (
    <div>
      <h2 className={classes.header}>
        <Button component={Link} to="/" variant="contained">
          <ChevronLeftIcon />
        </Button>
        <span>{t('stripe:stripe.header')}</span>
      </h2>

      {stripe_user_id && <p>{t('stripe:stripe.success')}</p>}

      <Loadable
        loading={shouldRedirect}
        message={t('stripe:stripe.redirecting')}
      >
        {null}
      </Loadable>

      <Loadable
        loading={shouldSendCode && loading}
        message={t('stripe:stripe.connecting')}
      >
        {null}
      </Loadable>

      {!stripe_user_id && error && <p className={classes.error}>{error}</p>}
    </div>
  );
};

export default withStyles(styles)(Stripe);
