import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { addDays, parseISO } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AlignerRating from './AlignerRating';
import Review from './Review';

import FetchFile from '../../../../FetchFile';

import { getAnglesFromVersion } from '../../../../../constants/photos.constant';
import AdditionalReviews from './additionalReviews/AdditionalReviews';
import { v2 } from '../../../../../axios';
import AlignerRemarks from './AlignerRemarks';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(6)
  },
  photo: {
    paddingTop: theme.spacing(4)
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  }
});

const Aligner = ({ patient, fetchPatient, match, classes }) => {
  const { t } = useTranslation(['patients', 'common']);
  const { aligners } = patient;
  const {
    params: { serie }
  } = match;
  const aligner = aligners.find(
    (item) => parseInt(item.serie, 10) === parseInt(serie, 10)
  );

  if (!aligner) {
    return <Redirect to={`/patient/${patient.id}/treatment/`} />;
  }

  const angles = getAnglesFromVersion(aligner.version);

  const handleReview = async (values) => {
    try {
      await v2.put(`/aligners/${aligner.id}`, values);

      await fetchPatient();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.response);
    }
  };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.titleContainer}>
            <IconButton
              color="primary"
              aria-label="View aligner"
              component={Link}
              to={`/patient/${patient.id}/treatment`}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">
              {t('patients:patients.viewAligner.title', {
                serie: aligner.serie,
                nbAligners: patient.nb_aligners
              })}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Typography color="textSecondary">
            {t('patients:patients.viewAligner.details.start')}
          </Typography>
          <Typography gutterBottom>
            {t('common:common.date.detailed', {
              date: parseISO(aligner.started_at)
            })}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Typography color="textSecondary">
            {t('patients:patients.viewAligner.details.end')}
          </Typography>
          {aligner.finished_at ? (
            <Typography gutterBottom>
              {t('common:common.date.detailed', {
                date: parseISO(aligner.finished_at)
              })}
            </Typography>
          ) : (
            <Typography gutterBottom>
              {t('common:common.date.detailed', {
                date: addDays(parseISO(aligner.started_at), 15)
              })}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} sm={4}>
          {aligner.finished_at && (
            <>
              <Typography color="textSecondary">
                {t('patients:patients.viewAligner.details.daysWorn')}
              </Typography>

              <Typography
                gutterBottom
                color={
                  aligner.days_worn < 15 || aligner.days_worn > 20
                    ? 'error'
                    : 'initial'
                }
              >
                {t('patients:patients.viewAligner.details.daysWornValue', {
                  days: aligner.days_worn
                })}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <AlignerRemarks remarks={aligner.remarks} />
        </Grid>

        <AlignerRating aligner={aligner} />

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {t('patients:patients.viewAligner.photos.title')}
          </Typography>
        </Grid>
        {angles.map((angle) => (
          <Grid
            key={angle.name}
            className={classes.photo}
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FetchFile
              receiverId={patient.id}
              receiverType="patient"
              type="treatment_photo"
              meta={{
                serie: aligner.serie,
                angle: angle.name
              }}
              fileName={`photo-serie-${aligner.serie}-${angle.name}-${patient.reference}`}
              showNotFound
              notFoundMessage="No picture"
              description={t(`patients:patients.angles.${angle.name}`)}
            />
          </Grid>
        ))}
      </Grid>

      <Review review={aligner} onReview={handleReview} />
      <AdditionalReviews
        aligner={aligner}
        onReview={fetchPatient}
        patient={patient}
      />
    </Paper>
  );
};

export default withStyles(styles)(Aligner);
