/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';

import { withStyles } from '@material-ui/core/styles';
import Loadable from './Loadable';
import Download from './Download';
import { getFile } from '../api/files';

const styles = (theme) => ({
  notFoundContainer: {
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.default
  }
});

const FetchFile = ({
  receiverId,
  receiverType,
  resourceId,
  resourceType,
  type,
  meta,
  fileName,
  onSuccess,
  showNotFound,
  notFoundMessage,
  hidden,
  description,
  refetch,
  classes,
  last,
  actionButtons,
  extension
}) => {
  const [file, setFile] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  // avoid rerender in useEffect
  const stringifiedMeta = JSON.stringify(meta);
  useEffect(() => {
    const fetchFile = async () => {
      setIsFetching(true);

      try {
        const fetchedFile = await getFile({
          receiver_type: receiverType,
          receiver_id: receiverId,
          ...(resourceId && { resource_id: resourceId }),
          ...(resourceType && { resource_type: resourceType }),
          type,
          meta: stringifiedMeta,
          last: last ? JSON.stringify(true) : false
        });

        if (!Object.keys(fetchedFile).length) {
          return;
        }

        setFile(fetchedFile);
        onSuccess?.(fetchedFile);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchFile();
  }, [
    refetch,
    last,
    onSuccess,
    stringifiedMeta,
    receiverId,
    receiverType,
    resourceId,
    resourceType,
    type
  ]);

  if ((!file && !showNotFound) || hidden) {
    return null;
  }

  if (!file && isFetching) {
    return (
      <div className={classes.notFoundContainer}>
        <Loadable loading message="Loading..." />
      </div>
    );
  }

  if (!file && showNotFound) {
    return (
      <div className={classes.notFoundContainer}>
        <BlockIcon />
        <Typography>{notFoundMessage || 'Aucun fichier'}</Typography>
      </div>
    );
  }

  return (
    <Download
      isFetching={isFetching}
      file={file}
      fileName={fileName}
      description={description}
      actionButtons={actionButtons}
      extension={extension}
    />
  );
};

FetchFile.propTypes = {
  receiverId: PropTypes.number.isRequired,
  receiverType: PropTypes.oneOf(['patient', 'practician']).isRequired,
  resourceId: PropTypes.number.isRequired,
  resourceType: PropTypes.oneOf(['booking']).isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.object,
  fileName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  showNotFound: PropTypes.bool,
  notFoundMessage: PropTypes.string,
  hidden: PropTypes.bool,
  description: PropTypes.string.isRequired
};

export default withStyles(styles)(FetchFile);
