import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchPatients from './SearchPatients';
import Results from './Results';
import Dialog from '../../Dialog';

const UnexpectedBooking = () => {
  const [patients, setPatients] = useState([]);
  const [open, setOpen] = useState(false);
  const [booking, setBooking] = useState(null);
  const { t } = useTranslation('bookings');

  const onSearch = (results) => {
    setPatients(results);
  };

  const onBook = (data) => {
    setBooking(data);
  };

  const handleClose = () => {
    setOpen(false);
    setPatients([]);
  };

  if (booking) {
    return <Redirect to={`/consultations/${booking.id}`} />;
  }

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          {t('bookings:bookings.unexpected.cta')}
        </Button>
      </Box>
      <Dialog
        open={open}
        title={t('bookings:bookings.unexpected.search.title')}
        onCancel={handleClose}
        cancelText={t('bookings:bookings.unexpected.search.close')}
      >
        {patients.length ? (
          <Results patients={patients} onBook={onBook} />
        ) : (
          <SearchPatients onSearch={onSearch} />
        )}
      </Dialog>
    </Box>
  );
};

export default UnexpectedBooking;
