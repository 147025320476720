import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { v2 } from '../../../axios';
import Select from '../../final-form/Select';
import Loadable from '../../Loadable';

const Results = ({ patients, onBook }) => {
  const { t } = useTranslation('bookings');
  const createBooking = async (values) => {
    try {
      const { data } = await v2.post('bookings', values);

      onBook(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <Box p={2}>
      <Form
        onSubmit={createBooking}
        initialValues={{
          patient_id: patients[0].id,
          type: 'pre_treatment'
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {patients.length > 1 ? (
                  <Field
                    name="patient_id"
                    render={(props) => (
                      <Select
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        formControlProps={{ fullWidth: true }}
                      />
                    )}
                    label="Patient"
                    showEmpty={false}
                    options={patients.map((p) => ({
                      value: p.id,
                      label: p.user.fullname
                    }))}
                  />
                ) : (
                  <Typography variant="h5" color="primary">
                    {patients[0].user.fullname}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="type"
                  render={(props) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Select {...props} formControlProps={{ fullWidth: true }} />
                  )}
                  label="Type"
                  showEmpty={false}
                  options={[
                    {
                      value: 'pre_treatment',
                      label: t('bookings:bookings.type.pre_treatment')
                    },
                    {
                      value: 'end_treatment',
                      label: t('bookings:bookings.type.end_treatment')
                    }
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  <Loadable loading={submitting} size={20} noMessage>
                    {t('bookings:bookings.unexpected.results.confirm')}
                  </Loadable>
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Box>
  );
};

export default Results;
