import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TextField from '../../../final-form/TextField';
import { v2 } from '../../../../axios';

const RequestReviewForm = ({ patient, onRequestReview }) => {
  const { t } = useTranslation('patients');
  const validate = (values) => {
    const errors = {};

    if (!values.comment) {
      errors.comment = 'Obligatoire';
    }

    return errors;
  };

  const handleReview = async ({ comment }) => {
    try {
      await v2.post(`treatment-plans/${patient.treatment_plan.id}/review`, {
        comment
      });

      onRequestReview();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <Grid item xs={12} align="right">
      <Form
        onSubmit={handleReview}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="comment"
              fullWidth
              label={t(
                'patients:patients.decideTreatment.requestReview.comment.label'
              )}
              component={TextField}
            />

            <Button variant="contained" color="primary" type="submit">
              {t(
                'patients:patients.decideTreatment.requestReview.comment.send'
              )}
            </Button>
          </form>
        )}
      />
    </Grid>
  );
};

export default RequestReviewForm;
