import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { differenceInYears, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  avatar: {
    width: 90,
    height: 90,
    margin: 'auto'
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium
  }
});

const Informations = ({ classes, patient }) => {
  const { t } = useTranslation(['bookings', 'common']);

  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Grid item md={2} sm={12} xs={12}>
        <Avatar className={classes.avatar} alt={patient.user.fullname}>
          <Typography variant="h3">
            {patient.user.lastname.charAt(0)}
            {patient.user.firstname.charAt(0)}
          </Typography>
        </Avatar>
      </Grid>
      <Grid item container spacing={2} md={10} sm={12} xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {patient.user.fullname}
          </Typography>
          <Typography variant="h6" align="center" color="error">
            {t(
              `bookings:bookings.viewBooking.patient.treatment.${patient.treatment_type}`
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography color="textSecondary" gutterBottom>
            {t('bookings:bookings.viewBooking.patient.informations.title')}
          </Typography>
          <Typography>
            <strong>
              {t(
                'bookings:bookings.viewBooking.patient.informations.reference'
              )}
            </strong>
            {patient.reference}
          </Typography>
          <Typography>
            <strong>
              {t(
                'bookings:bookings.viewBooking.patient.informations.dateOfBirth'
              )}
            </strong>
            {t('common:common.date.date', {
              date: parseISO(patient.user.birthday)
            })}
          </Typography>
          <Typography>
            <strong>
              {t('bookings:bookings.viewBooking.patient.informations.age')}
            </strong>
            {differenceInYears(new Date(), parseISO(patient.user.birthday))}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography color="textSecondary" gutterBottom>
            {t('bookings:bookings.viewBooking.patient.contact.title')}
          </Typography>
          <Typography>
            <strong>
              {t('bookings:bookings.viewBooking.patient.contact.email')}
            </strong>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${patient.user.email}`}
            >
              {patient.user.email}
            </a>
          </Typography>
          {patient.user.mobile && (
            <Typography gutterBottom>
              <strong>
                {t('bookings:bookings.viewBooking.patient.contact.mobile')}
              </strong>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`tel:${patient.user.mobile}`}
              >
                {patient.user.mobile}
              </a>
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Informations);
