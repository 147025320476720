import React from 'react';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

export const getAuthor = (comment) => {
  if (comment.authorable_type.includes('Administrator')) {
    return 'Joovence';
  }
  const { user } = comment.authorable;
  return `Dr. ${user.firstname} ${user.lastname}`;
};

export const getAvatarContent = (comment) => {
  if (comment.authorable_type.includes('Administrator')) {
    return <AllInclusiveIcon />;
  }

  const { user } = comment.authorable;

  return `${user.firstname.charAt(0)}${user.lastname.charAt(0)}`;
};
