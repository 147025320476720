import React from 'react';
import { Avatar, List, ListItem, ListItemAvatar } from '@material-ui/core';
import { getAvatarContent } from '../../../../utils/comments.utils';
import Comment from './Comment';

const ListComments = ({ comments }) => {
  return (
    <List>
      {comments.map((comment) => (
        <ListItem key={comment.id}>
          <ListItemAvatar>
            <Avatar>{getAvatarContent(comment)}</Avatar>
          </ListItemAvatar>
          <Comment comment={comment} />
        </ListItem>
      ))}
    </List>
  );
};

export default ListComments;
