import React, { useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AccountCircle from '@material-ui/icons/AccountCircle';
import Dashboard from '@material-ui/icons/Dashboard';
import CalendarToday from '@material-ui/icons/CalendarToday';
import HowToReg from '@material-ui/icons/HowToReg';
import PhoneIcon from '@material-ui/icons/Phone';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import HelpIcon from '@material-ui/icons/Help';
import Hidden from '@material-ui/core/Hidden';

import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { Receipt } from '@material-ui/icons';
import { UserContext } from '../store/User';

const drawerWidth = 240;

const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar
});

const Category = ({ icon, primary, url }) => {
  const CustomLink = useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Link ref={ref} to={url} {...linkProps} />
      )),
    [url]
  );

  return (
    <li>
      <ListItem button component={CustomLink}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

const Content = ({ classes }) => {
  const user = useContext(UserContext);

  const { t } = useTranslation('common');
  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <Category
          icon={<Dashboard />}
          primary={t('common:common.sideBar.dashboard')}
          url="/"
        />
        {user.canAccessBookings && (
          <Category
            icon={<HowToReg />}
            primary={t('common:common.sideBar.bookings')}
            url="/consultations"
          />
        )}
        {user.canAccessTreatments && (
          <Category
            icon={<AccountCircle />}
            primary={t('common:common.sideBar.patients')}
            url="/patients"
          />
        )}
        {user.canAccessBookings && (
          <Category
            icon={<CalendarToday />}
            primary={t('common:common.sideBar.agenda')}
            url="/agenda"
          />
        )}
        {user.canAccessBookingRequests && (
          <Category
            icon={<PhoneIcon />}
            primary={t('common:common.sideBar.bookingRequests')}
            url="/reservations"
          />
        )}
        {!!user.canAccessPickups && (
          <Category
            icon={<LocalShippingIcon />}
            primary={t('common:common.sideBar.pickups')}
            url="/pickups"
          />
        )}
        <Category
          icon={<Receipt />}
          primary={t('common:common.sideBar.invoices')}
          url="/invoices"
        />
        <ListItem
          button
          component="a"
          href="https://faq.joovence.com/fr/collections/3280299-joovence-support-praticiens"
          target="_blank"
        >
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:common.sideBar.assistance')} />
        </ListItem>
        {/* <Category
						icon={<Receipt />}
						primary="Facturation"
						url="/facturation"
					/> */}
      </List>
    </div>
  );
};

const SideBar = ({ classes, theme, handleDrawerToggle, container, open }) => {
  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <Content classes={classes} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          <Content classes={classes} />
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default withStyles(styles, { withTheme: true })(SideBar);
