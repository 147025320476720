import React, { useEffect, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import Loadable from '../../Loadable';
import { v2 } from '../../../axios';
import InteractiveRender from './TreatmentPlanInteractiveRenderer';
import StaticRender from './TreatmentPlanStaticRenderer';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }
});

const TreatmentPlan = ({ classes, patient }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [treatmentPlan, setTreatmentPlan] = useState(null);
  const { t } = useTranslation(['treatmentPlan', 'common']);

  useEffect(() => {
    const fetchTreatmentPlan = async () => {
      setIsFetching(true);
      try {
        const { data: fetchedTreatmentPlan } = await v2.get(
          `/treatment-plans/${patient.treatment_plan.id}`
        );
        setTreatmentPlan(fetchedTreatmentPlan);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchTreatmentPlan();
  }, [patient.id]);

  if (!treatmentPlan) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h5">
        {t('treatmentPlan:treatmentPlan.title')}
      </Typography>

      <Loadable loading={isFetching} message="Preparing the treatment plan">
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={4}>
            <Typography align="center" color="textSecondary">
              {t('treatmentPlan:treatmentPlan.numberOfAligner')}
            </Typography>
            <Typography align="center" variant="h5">
              {patient.nb_aligners}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" color="textSecondary">
              {t('treatmentPlan:treatmentPlan.treatmentDuration')}
            </Typography>
            <Typography align="center" variant="h5">
              {t('treatmentPlan:treatmentPlan.treatmentDurationValue', {
                duration: patient.treatment_duration
              })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" color="textSecondary">
              {t('treatmentPlan:treatmentPlan.availableSince')}
            </Typography>
            <Typography align="center" variant="h5">
              {t('common:common.date.ago', {
                date: parseISO(patient.treatment_plan_uploaded_at)
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {treatmentPlan.is_complete &&
            treatmentPlan.available_at &&
            treatmentPlan.available ? (
              treatmentPlan.type === 'interactive' ? (
                <InteractiveRender treatmentPlan={treatmentPlan} />
              ) : (
                <StaticRender treatmentPlan={treatmentPlan} />
              )
            ) : (
              <Typography>
                {t('treatmentPlan:treatmentPlan.notAvailable')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Loadable>
    </Paper>
  );
};

export default withStyles(styles)(TreatmentPlan);
