import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@material-ui/core';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useTranslation } from 'react-i18next';
import { v2Base } from '../../axios';
import Loadable from '../Loadable';
import { formatCurrency } from '../../utils/formats';

const ListInvoices = () => {
  const { t } = useTranslation(['invoices', 'common']);
  const [invoices, setInvoices] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchInvoices = async () => {
    setIsFetching(true);

    try {
      const { data: fetchedInvoices } = await v2Base.get('/octobat-invoices');
      setInvoices(fetchedInvoices.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const PdfUrlFormatter = ({ row: invoice }) => {
    return (
      <a href={invoice.pdf_file_url} download target="_blank" rel="noreferrer">
        {t('common:common.download')}
      </a>
    );
  };

  const DowloadableTypeProvider = (props) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DataTypeProvider formatterComponent={PdfUrlFormatter} {...props} />;
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <Loadable loading={isFetching}>
      <Box p={2} display="flex">
        <Grid
          rows={invoices}
          columns={[
            {
              name: 'invoice_number',
              title: t('invoices:invoices.list.table.invoiceNumber')
            },
            {
              name: 'invoice_date',
              title: t('invoices:invoices.list.table.invoiceDate')
            },
            {
              name: 'total_gross_amount',
              title: t('invoices:invoices.list.table.totalGrossAmount'),
              getCellValue: (invoice) =>
                formatCurrency(invoice.total_gross_amount / 100)
            },
            {
              name: 'pdf_file_url',
              title: t('invoices:invoices.list.table.pdfFileUrl')
            }
          ]}
        >
          <DowloadableTypeProvider for={['pdf_file_url']} />
          <Table />
          <TableHeaderRow />
          <Toolbar />
        </Grid>
      </Box>
    </Loadable>
  );
};

export default ListInvoices;
