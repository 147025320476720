import React, { Fragment, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CachedIcon from '@material-ui/icons/Cached';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';

import { withStyles } from '@material-ui/core/styles';

import grey from '@material-ui/core/colors/grey';
import { Fab } from '@material-ui/core';
import { uploadToS3 } from '../utils/vapor';

import FetchFile from './FetchFile';
import { v2 } from '../axios';
import Loadable from './Loadable';

const styles = (theme) => ({
  root: {
    textAlign: 'center',
    width: '100%'
  },
  content: {
    padding: theme.spacing(2)
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed',
    borderColor: grey[300],
    height: 150,
    cursor: 'pointer',
    overflow: 'hidden',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  button: {
    margin: theme.spacing()
  },
  icon: {
    marginLeft: theme.spacing()
  }
});

const Upload = ({
  autoUpload,
  receiverId,
  receiverType,
  resourceId,
  resourceType,
  type,
  meta,
  onSuccess,
  classes,
  description,
  // retryable,
  backgroundImage,
  readOnly,
  ...rest
}) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const [refreshFetch, setRefreshFetch] = useState(false);
  const [retryMode, setRetryMode] = useState(false);

  const onFetch = useCallback((fetchedFile) => {
    if (fetchedFile && Object.keys(fetchedFile).length) {
      setHasFile(true);
      setRetryMode(false);
      if (onSuccess) {
        onSuccess(fetchedFile);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleUpload = useCallback(
    async (acceptedFile) => {
      try {
        setIsUploading(true);

        const temporaryUploadedFile = await uploadToS3(acceptedFile);

        const data = {
          file_key: temporaryUploadedFile.key,
          receiver_type: receiverType,
          receiver_id: receiverId,
          ...(resourceId && { resource_id: resourceId }),
          ...(resourceType && { resource_type: resourceType }),
          type
        };

        if (meta) {
          data.meta = meta;
        }

        const { data: uploadedFile } = await v2.post('files', data);

        setHasFile(true);
        setRetryMode(true);
        setRefreshFetch((currentRefreshFetch) => !currentRefreshFetch);

        if (onSuccess) {
          onSuccess(uploadedFile);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsUploading(false);
      }
    },
    [meta, onSuccess, receiverId, receiverType, type]
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0];
      setFile(acceptedFile);

      if (acceptedFile && autoUpload) {
        await handleUpload(acceptedFile);
      }
    },
    [autoUpload, handleUpload]
  );

  const retry = (
    <Tooltip title="Remplacer" placement="bottom">
      <Fab
        size="small"
        component="span"
        color="secondary"
        onClick={() => {
          setHasFile(false);
          setRetryMode(true);
        }}
        className={classes.button}
      >
        <CachedIcon />
      </Fab>
    </Tooltip>
  );

  const fetch = (
    <FetchFile
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      meta={meta}
      receiverType={receiverType}
      receiverId={receiverId}
      resourceType={resourceType}
      resourceId={resourceId}
      type={type}
      hidden={retryMode}
      description={description}
      onSuccess={onFetch}
      actionButtons={<>{hasFile && !retryMode && !readOnly && retry}</>}
      refetch={refreshFetch}
    />
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    disabled: isUploading
  });

  const drop = (
    <>
      <div
        className={classes.dropzone}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          justifyContent: backgroundImage ? 'flex-end' : 'space-around'
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getRootProps()}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} />
        <Loadable loading={isUploading} message="Upload en cours...">
          {!backgroundImage && <CloudUploadIcon className={classes.icon} />}
          <Typography
            color={backgroundImage ? 'primary' : 'textSecondary'}
            component="h2"
          >
            {isDragActive
              ? 'Glissez votre fichier ici'
              : 'Cliquer pour selectionner un fichier...'}
          </Typography>
          <Typography>{file && file.name}</Typography>
        </Loadable>
      </div>
    </>
  );

  const cancelRetryButton = retryMode && !isUploading && (
    <Button
      onClick={() => {
        setHasFile(true);
        setRetryMode(false);
      }}
      size="small"
      variant="contained"
      component="span"
      className={classes.button}
      color="secondary"
    >
      Annuler <ClearIcon />
    </Button>
  );

  return (
    <Paper className={classes.root}>
      <div className={classes.content}>
        <Typography gutterBottom color="textSecondary">
          {description}
        </Typography>

        {fetch}
        {(retryMode || !hasFile) && drop}
        {retryMode && cancelRetryButton}
      </div>
    </Paper>
  );
};

export default withStyles(styles)(Upload);
