import React from 'react';

import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import Header from '../Header';

import ListPickups from './ListPickups';

const Pickups = () => {
  const { t } = useTranslation('pickups');

  return (
    <Paper>
      <Header
        breadcrumbs={[
          { name: t('pickups:pickups.list.title'), url: '/pickups' }
        ]}
      />
      <ListPickups />
    </Paper>
  );
};

export default Pickups;
