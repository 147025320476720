import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Upload from '../../Upload';

const DigitalImpression = ({ booking, onSuccess }) => {
  const { t } = useTranslation('bookings');

  const handleAllowOneScan = (file) => {
    if (file.mime_type === 'application/zip') {
      onSuccess('is_allowed_to_upload_one_scan', true);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Upload
          description={t('bookings:bookings.viewBooking.scan.upper')}
          receiverId={booking.patient.id}
          receiverType="patient"
          resourceId={booking.id}
          resourceType="booking"
          type="treatment_scan"
          fileName={`scan-maxillaire-${booking.patient.reference}`}
          readOnly={booking.status === 'done'}
          retryable
          meta={{
            angle: 'upper',
            serie: booking.type
          }}
          autoUpload
          onSuccess={(file) => {
            onSuccess('digital_impression_upper', true);
            handleAllowOneScan(file);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Upload
          description={t('bookings:bookings.viewBooking.scan.lower')}
          receiverId={booking.patient.id}
          receiverType="patient"
          resourceId={booking.id}
          resourceType="booking"
          type="treatment_scan"
          fileName={`scan-mandibulaire-${booking.patient.reference}`}
          readOnly={booking.status === 'done'}
          retryable
          meta={{
            angle: 'lower',
            serie: booking.type
          }}
          autoUpload
          onSuccess={(file) => {
            onSuccess('digital_impression_lower', true);
            handleAllowOneScan(file);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Upload
          description={t('bookings:bookings.viewBooking.scan.occlusion')}
          receiverId={booking.patient.id}
          receiverType="patient"
          resourceId={booking.id}
          resourceType="booking"
          type="treatment_scan"
          fileName={`scan-occlusion-${booking.patient.reference}`}
          readOnly={booking.status === 'done'}
          retryable
          meta={{
            angle: 'occlusion',
            serie: booking.type
          }}
          autoUpload
          onSuccess={(file) => {
            onSuccess('digital_impression_occlusion', true);
            handleAllowOneScan(file);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Upload
          description={t(
            'bookings:bookings.viewBooking.scan.occlusionAdditional'
          )}
          receiverId={booking.patient.id}
          receiverType="patient"
          resourceId={booking.id}
          resourceType="booking"
          type="treatment_scan"
          fileName={`scan-occlusion-additional-${booking.patient.reference}`}
          readOnly={booking.status === 'done'}
          retryable
          meta={{
            angle: 'occlusion_additional',
            serie: booking.type
          }}
          autoUpload
          onSuccess={(file) => {
            onSuccess('digital_impression_occlusion_additional', true);
            handleAllowOneScan(file);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DigitalImpression;
