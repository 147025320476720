/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';

import { addMinutes, differenceInMinutes, endOfDay, parseISO } from 'date-fns';
import enUs from 'date-fns/locale/en-US';

import { RRule } from 'rrule';

import { green, grey } from '@material-ui/core/colors';
import { v2, v2Base } from '../../axios';
import { ShowBooking } from './ShowBooking';
import { DeleteAvailability } from './DeleteAvailability';
import { DeleteRecurrentAvailability } from './DeleteRecurrentAvailability';
import AddAvailability from './AddAvailability';
import { occurenceIsExcluded } from './helpers';

const locales = {
  'en-US': enUs
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  endOfDay,
  getDay,
  locales
});

const DragAndDropCalendar = withDragAndDrop(Calendar);

const styles = (theme) => ({
  root: {
    height: '100%'
  },
  calendarContainer: {
    padding: theme.spacing(2),
    height: '700px'
  },
  availability: {
    background: theme.palette.primary.main
  },
  recurrentAvailability: {
    background: green[600]
  },
  excludedRecurrentAvailability: {
    background: grey[600]
  },
  booking: {
    background: theme.palette.secondary.main
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

const Agenda = ({ snackbar, classes, user }) => {
  const { t } = useTranslation(['agenda', 'common']);
  const [availabilities, setAvailabilities] = useState([]);
  const [recurrentAvailabilities, setRecurrentAvailabilities] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [toAdd, setToAdd] = useState(null);
  const [showAvailabilities, setShowAvailabilities] = useState(true);
  const [showBookings, setShowBookings] = useState(true);
  const [showRecurrentAvailability, setShowRecurrentAvailability] =
    useState(true);

  const fetchAvailabilities = async () => {
    try {
      const { data: fetchedAvailabilities } = await v2.get('availabilities');

      setAvailabilities(fetchedAvailabilities);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchRecurrentAvailabilities = async () => {
    try {
      const { data: fetchedRecurrentAvailabilities } = await v2Base.get(
        'recurrent-availabilities'
      );

      setRecurrentAvailabilities(fetchedRecurrentAvailabilities);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchBookings = async () => {
    try {
      const { data: fetchedBookings } = await v2.get('bookings');

      setBookings(fetchedBookings);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchEvents = useCallback(
    async (notification = true) => {
      await Promise.all([
        fetchAvailabilities(),
        fetchBookings(),
        fetchRecurrentAvailabilities()
      ]);

      if (notification) {
        snackbar.open(t('agenda:agenda.reloaded'), 'success');
      }
    },
    [t, snackbar]
  );

  useEffect(() => {
    fetchEvents(false);
  }, []);

  const getEvents = () => {
    let recurrentAvailabilitiesEvents = [];

    if (showRecurrentAvailability && recurrentAvailabilities.length) {
      recurrentAvailabilities.map((ra) => {
        const [startHours, startMinutes] = ra.start.split(':');
        const [endHours, endMinutes] = ra.end.split(':');

        const rule = new RRule({
          freq: RRule.WEEKLY,
          dtstart: new Date(ra.begins_at),
          until: new Date(ra.expires_at),
          interval: 1
        });

        // Injecting recurrent dates into calendar
        // eslint-disable-next-line no-restricted-syntax
        for (const date of rule.all()) {
          const startDate = new Date(date);
          startDate.setHours(startHours);
          startDate.setMinutes(startMinutes);

          const endDate = new Date(date);
          endDate.setHours(endHours);
          endDate.setMinutes(endMinutes);

          recurrentAvailabilitiesEvents = [
            ...recurrentAvailabilitiesEvents,
            {
              id: ra.id,
              start: startDate,
              end: endDate,
              title: occurenceIsExcluded(ra, date)
                ? t('agenda:agenda.notAvailable')
                : t('agenda:agenda.available'),
              draggable: false,
              type: 'recurrent-availability',
              data: ra
            }
          ];
        }
        return recurrentAvailabilitiesEvents;
      });
    }

    const availabilitiesEvents =
      (showAvailabilities &&
        availabilities &&
        availabilities.map((a) => ({
          id: a.id,
          start: parseISO(a.start),
          end: parseISO(a.end),
          title: t('agenda:agenda.available'),
          draggable: false,
          type: 'availability'
        }))) ||
      [];

    const bookingsEvents =
      (showBookings &&
        bookings.length &&
        bookings.map((b) => ({
          id: b.id,
          start: parseISO(b.date),
          end: addMinutes(parseISO(b.date), b.practician.duration_booking),
          title: b.patient.user.fullname,
          draggable: true,
          type: 'booking'
        }))) ||
      [];

    return [
      ...bookingsEvents,
      ...availabilitiesEvents,
      ...recurrentAvailabilitiesEvents
    ];
  };

  const create = (event) => {
    let { end } = event;
    const { duration_booking } = user.practician;
    const duration = differenceInMinutes(event.end, event.start);

    if (duration < duration_booking) {
      end = addMinutes(event.start, duration_booking);
    }

    setToAdd({ ...event, end });
  };

  const handleSelect = (eventToSelect) => {
    setSelectedEvent(eventToSelect);
  };

  const onDeleteAvailability = async () => {
    fetchAvailabilities();
    setSelectedEvent(null);
  };

  const onDeleteRecurrentAvailability = async () => {
    fetchRecurrentAvailabilities();
    setSelectedEvent(null);
  };

  const handleAddAvailability = async (isRecurrent = false) => {
    if (isRecurrent) {
      await fetchRecurrentAvailabilities();
    } else {
      await fetchAvailabilities();
    }
    setToAdd(null);
  };

  const getEventClassName = (event) => {
    switch (event.type) {
      case 'booking':
        return classes.booking;
      case 'availability':
        return classes.availability;
      case 'recurrent-availability':
        if (occurenceIsExcluded(event.data, event.start)) {
          return classes.excludedRecurrentAvailability;
        }
        return classes.recurrentAvailability;
      default:
        return {};
    }
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.calendarContainer}>
        <Button variant="outlined" onClick={fetchEvents}>
          {t('agenda:agenda.reload')}
        </Button>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAvailabilities}
                onChange={() => setShowAvailabilities((show) => !show)}
                color="primary"
              />
            }
            label={t('agenda:agenda.availabilities.title')}
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={showRecurrentAvailability}
                onChange={() => setShowRecurrentAvailability((show) => !show)}
              />
            }
            label={t('agenda:agenda.recurrentAvailabilities.title')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showBookings}
                onChange={() => setShowBookings((show) => !show)}
              />
            }
            label={t('agenda:agenda.bookings.title')}
          />
        </FormGroup>
        <DragAndDropCalendar
          localizer={localizer}
          resizable
          selectable
          events={getEvents()}
          // views={["agenda", "week", 'day']}
          drilldownView="week"
          min={new Date(2018, 1, 1, 7, 0, 0)}
          max={new Date(2018, 1, 1, 20, 0, 0)}
          onSelectSlot={create}
          defaultView="week"
          onSelectEvent={handleSelect}
          step={15}
          eventPropGetter={(e) => ({
            className: getEventClassName(e)
          })}
        />

        {selectedEvent &&
          // eslint-disable-next-line no-nested-ternary
          (selectedEvent.type === 'booking' ? (
            <ShowBooking
              event={selectedEvent}
              onClose={() => setSelectedEvent(null)}
            />
          ) : selectedEvent.type === 'availability' ? (
            <DeleteAvailability
              event={selectedEvent}
              snackbar={snackbar}
              onDelete={onDeleteAvailability}
              onCancel={() => setSelectedEvent(false)}
            />
          ) : (
            <DeleteRecurrentAvailability
              event={selectedEvent}
              snackbar={snackbar}
              onDelete={onDeleteRecurrentAvailability}
              onCancel={() => setSelectedEvent(false)}
            />
          ))}

        {toAdd && (
          <AddAvailability
            onAdd={handleAddAvailability}
            onError={() => setToAdd(null)}
            onCancel={() => setToAdd(null)}
            event={toAdd}
            snackbar={snackbar}
            user={user}
          />
        )}
      </div>
    </Paper>
  );
};

export default withStyles(styles)(Agenda);
