import React, { useEffect, useState } from 'react';
import mime from 'mime-types';

import Tooltip from '@material-ui/core/Tooltip';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FolderIcon from '@material-ui/icons/Folder';
import { STLViewer } from 'react-stl-obj-viewer';

import { withStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import Loadable from './Loadable';

import { getFileContent, isMimeTypeImage } from '../utils/files';

const styles = (theme) => ({
  thumbnailContainer: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  thumbnail: {
    maxWidth: '100%',
    maxHeight: '100%',
    cursor: 'pointer'
  },
  icon: {
    width: '80px',
    height: '80px',
    cursor: 'pointer',
    color: theme.palette.info.main
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing()
  }
});

const Download = ({
  file,
  classes,
  description,
  fileName,
  isFetching,
  actionButtons
}) => {
  const [fileContent, setFileContent] = useState(null);

  useEffect(() => {
    const fetchFileContent = async () => {
      // no need to fetch content if image
      if (isMimeTypeImage(file.mime_type)) {
        return;
      }
      const fetchedFileContent = await getFileContent(file.url);
      setFileContent(fetchedFileContent);
    };
    fetchFileContent();
  }, [file.mime_type, file.url]);

  const getFileName = () => {
    const extension = mime.extension(file.mime_type);

    if (!fileName) {
      return `${file.filename}.${extension}`;
    }

    return `${fileName}.${extension}`;
  };

  const getStlRenderFile = () => {
    return new File([fileContent], getFileName(), {
      type: file.mime_type
    });
  };

  if (!file || Object.keys(file).length === 0) {
    return null;
  }

  const renderPreview = () => {
    if (isMimeTypeImage(file.mime_type)) {
      return (
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          <img
            className={classes.thumbnail}
            src={file.url}
            alt={description || fileName}
          />
        </a>
      );
    }

    if (file.mime_type === 'model/stl') {
      if (!fileContent) {
        return null;
      }

      return (
        <div>
          <STLViewer
            file={getStlRenderFile()}
            modelColor="#FFFFF"
            backgroundColor="#FAACA8"
          />
        </div>
      );
    }

    return (
      <a href={file.url} target="_blank" rel="noopener noreferrer">
        <div>
          <FolderIcon fontSize="inherit" className={classes.icon} />
        </div>
      </a>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.thumbnailContainer}>
        <Loadable loading={isFetching}>{renderPreview()}</Loadable>
      </div>
      <div className={classes.buttonContainer}>
        <Tooltip title="Télécharger" placement="bottom">
          <Fab
            size="small"
            color="secondary"
            className={classes.button}
            href={file.url}
            target="_blank"
            rel="noopener"
          >
            <SaveAltIcon />
          </Fab>
        </Tooltip>
        {actionButtons}
      </div>
    </div>
  );
};

export default withStyles(styles)(Download);
