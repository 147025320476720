const TREATMENT = 'TREATMENT';
const LAB_REPORT = 'LAB_REPORT';
const PHOTOS = 'PHOTOS';
const COMMENTS = 'COMMENTS';
const MEDICAL_INFORMATIONS = 'MEDICAL_INFORMATIONS';
const TREATMENT_PLAN = 'TREATMENT_PLAN';
const SETTINGS = 'SETTINGS';

export const PATIENT_TABS = {
  TREATMENT,
  LAB_REPORT,
  PHOTOS,
  COMMENTS,
  MEDICAL_INFORMATIONS,
  TREATMENT_PLAN,
  SETTINGS
};
