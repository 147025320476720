import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../Dialog';

export const ShowBooking = ({ event, onClose }) => {
  const { t } = useTranslation(['agenda', 'common']);
  if (!event) return null;
  return (
    <Dialog
      open
      title={t('agenda:agenda.bookings.view.title')}
      description={t('agenda:agenda.bookings.view.description', {
        patient: event.title,
        date: t('common:common.date.date', {
          date: event.start
        }),
        time: t('common:common.date.time', {
          date: event.start
        })
      })}
      onAccept={onClose}
      confirmText={t('agenda:agenda.bookings.view.close')}
    />
  );
};
