/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { SnackbarProvider } from './Snackbar';
import SideBar from './SideBar';
import AppBar from './AppBar';
import Dialog from './Dialog';
import Content from './Content';

import { v2, api } from '../axios';

import { TagsContext } from '../store/Tags';

import { UserContext } from '../store/User';

import { getCGULink } from '../utils/users.utils';
import {
  PRACTICIAN_GENERALIST,
  PRACTICIAN_SPECIALIST,
  PRACTICIAN_MONITORING,
  PRACTICIAN_DISTRIBUTOR,
  hasRole
} from '../utils/roles';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    padding: theme.spacing(2)
  }
});

const Authenticated = ({ classes, logout, user, fetchUser }) => {
  const [opened, setOpened] = useState(false);
  const [tags, setTags] = useState([]);

  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    i18n.changeLanguage(user.preferred_locale);
  }, [i18n, user.preferred_locale]);

  const handleDrawerToggle = () => {
    setOpened((currentOpened) => !currentOpened);
  };

  const isGeneralist = hasRole(user.roles, PRACTICIAN_GENERALIST);
  const isSpecialist = hasRole(user.roles, PRACTICIAN_SPECIALIST);
  const canMonitor = hasRole(user.roles, PRACTICIAN_MONITORING);
  const isDistributor = hasRole(user.roles, PRACTICIAN_DISTRIBUTOR);

  const canAccessBookings = isGeneralist || isSpecialist;
  const canAccessTreatments = isSpecialist || canMonitor;
  const canAccessBookingRequests = user.practician.accepts_booking_requests;
  const canAccessPickups = user.practician.can_request_pickup;

  const userWithRoles = {
    ...user,
    canAccessBookings,
    canAccessTreatments,
    canAccessBookingRequests,
    isGeneralist,
    isSpecialist,
    canMonitor,
    canAccessPickups,
    isDistributor
  };

  const acceptLegal = async () => {
    try {
      await v2.get('accept-legal');
      await fetchUser();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchTags = async () => {
    try {
      const { data: newTags } = await api.get('/v2/tags');
      setTags(newTags);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  if (user && isDistributor) {
    return window.location.replace(process.env.REACT_APP_DISTRIBUTOR_URL);
  }

  if (user && !user.accepted_cgu_at) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Dialog
          open
          onAccept={acceptLegal}
          title={t('common:common.gcu.title')}
          description={
            <span>
              <Trans t={t} i18nKey="gcu.description">
                {"Je confirme que j'accepte les "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getCGULink(user.address.country)}
                >
                  Conditions Générales d'Utilisation.
                </a>
              </Trans>
            </span>
          }
          confirmText={t('common:common.gcu.accept')}
        />
      </main>
    );
  }

  return (
    <UserContext.Provider value={userWithRoles}>
      <AppBar
        isDrawerOpen={opened}
        handleDrawerToggle={handleDrawerToggle}
        logout={logout}
      />
      <SideBar open={opened} handleDrawerToggle={handleDrawerToggle} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <SnackbarProvider>
          <TagsContext.Provider value={tags}>
            <Content user={user} fetchUser={fetchUser} />
          </TagsContext.Provider>
        </SnackbarProvider>
      </main>
    </UserContext.Provider>
  );
};

export default withStyles(styles, { withTheme: true })(Authenticated);
