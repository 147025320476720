import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import Rating from './Rating';

const AlignerRating = ({ aligner }) => {
  const { t } = useTranslation('patients');

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {t('patients:patients.viewAligner.rating.title')}
        </Typography>
      </Grid>
      {aligner.global_satisfaction !== null && (
        <Grid item xs={12}>
          <Typography
            gutterBottom
            variant="h6"
            align="center"
            color="textSecondary"
          >
            {t('patients:patients.viewAligner.rating.globalSatisfaction')}
          </Typography>
          <Typography gutterBottom color="primary" align="center" variant="h5">
            {aligner.global_satisfaction}/5
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={6}>
        <Typography
          gutterBottom
          variant="h6"
          align="center"
          color="textSecondary"
        >
          {t('patients:patients.viewAligner.rating.upper')}
        </Typography>
        <Typography gutterBottom color="primary" align="center">
          {t('patients:patients.viewAligner.rating.fitting.title')}
        </Typography>
        <Rating
          value={aligner.fitting_upper}
          labels={[
            t('patients:patients.viewAligner.rating.fitting.loose'),
            t('patients:patients.viewAligner.rating.fitting.normal'),
            t('patients:patients.viewAligner.rating.fitting.tight')
          ]}
        />
        <Typography gutterBottom color="primary" align="center">
          {t('patients:patients.viewAligner.rating.pain.title')}
        </Typography>
        <Rating
          value={aligner.pain_upper}
          labels={[
            t('patients:patients.viewAligner.rating.pain.painless'),
            t('patients:patients.viewAligner.rating.pain.painful')
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography
          gutterBottom
          variant="h6"
          align="center"
          color="textSecondary"
        >
          {t('patients:patients.viewAligner.rating.lower')}
        </Typography>
        <Typography gutterBottom />
        <Typography gutterBottom color="primary" align="center">
          {t('patients:patients.viewAligner.rating.fitting.title')}
        </Typography>
        <Rating
          value={aligner.fitting_lower}
          labels={[
            t('patients:patients.viewAligner.rating.fitting.loose'),
            t('patients:patients.viewAligner.rating.fitting.normal'),
            t('patients:patients.viewAligner.rating.fitting.tight')
          ]}
        />
        <Typography gutterBottom color="primary" align="center">
          {t('patients:patients.viewAligner.rating.pain.title')}
        </Typography>
        <Rating
          value={aligner.pain_lower}
          labels={[
            t('patients:patients.viewAligner.rating.pain.painless'),
            t('patients:patients.viewAligner.rating.pain.painful')
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default AlignerRating;
