import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import DecideButtons from './DecideButtons';
import RequestReviewForm from './RequestReviewForm';
import {
  isPrescriptorPractician,
  isSpecialistPractician
} from '../../../../utils/patients.utils';

const NoDecision = ({ patient, fetchPatient, snackbar }) => {
  const { t } = useTranslation('patients');
  const [showRequestReviewForm, setShowRequestReviewForm] = useState(false);

  const handleRequestReview = async () => {
    setShowRequestReviewForm(false);
    await fetchPatient();
  };

  if (!isPrescriptorPractician(patient) && !isSpecialistPractician(patient)) {
    return (
      <Typography>
        {t(
          'patients:patients.decideTreatment.decision.localPractician.noDecision'
        )}
      </Typography>
    );
  }

  return (
    <>
      {!patient.treatment_plan_needs_review ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">
              {t('patients:patients.decideTreatment.title')}
            </Typography>
          </Grid>
          <DecideButtons
            patient={patient}
            fetchPatient={fetchPatient}
            snackbar={snackbar}
          />
          <Grid item xs={12} sm={6}>
            <Button
              size="small"
              variant="outlined"
              fullWidth
              component="span"
              color="primary"
              onClick={() => {
                setShowRequestReviewForm((show) => !show);
              }}
            >
              {showRequestReviewForm
                ? t('patients:patients.decideTreatment.requestReview.cancel')
                : t('patients:patients.decideTreatment.requestReview.label')}
            </Button>
          </Grid>
        </>
      ) : (
        <Typography>
          {t('patients:patients.decideTreatment.requestReview.description')}
        </Typography>
      )}
      {showRequestReviewForm && (
        <RequestReviewForm
          patient={patient}
          onRequestReview={handleRequestReview}
        />
      )}
    </>
  );
};

export default NoDecision;
