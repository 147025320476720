import React from 'react';
import { Form, Field } from 'react-final-form';
import { Grid, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import Select from '../../../../final-form/Select';
import TextField from '../../../../final-form/TextField';

const Review = ({ review, onReview }) => {
  const { t } = useTranslation(['patients', 'common']);
  const validate = (values) => {
    if (!values.decision) {
      return { decision: 'Select decision' };
    }

    if (values.decision === 'chewies' && !values.chewies_days) {
      return { chewies_days: 'Specify additional days' };
    }

    return {};
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {review.is_pending_review
            ? t('patients:patients.viewAligner.review.toReview')
            : t('patients:patients.viewAligner.review.reviewed')}
        </Typography>

        {review.reviewed_at ? (
          <Typography gutterBottom>
            {t('patients:patients.viewAligner.review.decision', {
              decision: review.decision,
              date: t('common:common.date.detailed', {
                date: parseISO(review.reviewed_at)
              })
            })}
          </Typography>
        ) : (
          <Form
            onSubmit={onReview}
            validate={validate}
            initialValues={{ decision: null }}
            render={({ handleSubmit, submitting, values }) => (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="decision"
                    render={(props) => (
                      <Select
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        formControlProps={{ fullWidth: true }}
                      />
                    )}
                    label={t(
                      'patients:patients.viewAligner.review.form.decision.label'
                    )}
                    showEmpty
                    options={[
                      { value: 'fit', label: 'Fit' },
                      { value: 'unfit', label: 'Unfit' },
                      { value: 'chewies', label: 'Chewies' },
                      {
                        value: 'incorrect_photos',
                        label: t(
                          'patients:patients.viewAligner.review.form.decision.incorrect_photos'
                        )
                      }
                    ]}
                  />
                </Grid>
                {values.decision === 'chewies' && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="chewies_days"
                      label={t(
                        'patients:patients.viewAligner.review.form.chewies.label'
                      )}
                      component={TextField}
                      type="number"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    disabled={submitting}
                  >
                    {submitting
                      ? t(
                          'patients:patients.viewAligner.review.form.submit.loading'
                        )
                      : t(
                          'patients:patients.viewAligner.review.form.submit.label'
                        )}
                  </Button>
                </Grid>
              </Grid>
            )}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Review;
