import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Paper } from '@material-ui/core';

import {
  Grid as GridDX,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  SearchState,
  IntegratedFiltering
} from '@devexpress/dx-react-grid';

import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { v2 } from '../../../axios';
import Header from '../../Header';
import SelectStatus from './SelectStatus';
import Loadable from '../../Loadable';
import { STATUS_CONFIRMED } from '../bookings.utils';
import UnexpectedBooking from '../unexpectedBooking/UnexpectedBooking';

const codeColumns = ['patient'];

const CodeFormatter = ({ row: booking }) => (
  <Link to={`/consultations/${booking.id}`}>
    {booking.patient.user.fullname}
  </Link>
);

const CodeTypeProvider = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DataTypeProvider formatterComponent={CodeFormatter} {...props} />
);

const ListBookings = () => {
  const { t } = useTranslation(['bookings', 'common']);
  const [bookings, setBookings] = useState([]);
  const [status, setStatus] = useState(STATUS_CONFIRMED);
  const [isFetching, setIsFetching] = useState(false);

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  useEffect(() => {
    const fetchBookings = async () => {
      setIsFetching(true);
      const orderBy = status === STATUS_CONFIRMED ? 'asc' : 'desc';
      try {
        const { data: fetchedBookings } = await v2.get('bookings', {
          params: { status, order_by: orderBy }
        });

        setBookings(fetchedBookings);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    };

    try {
      fetchBookings();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [status]);

  return (
    <Paper>
      <Header
        breadcrumbs={[
          { name: t('bookings:bookings.list.title'), url: '/consultations' }
        ]}
      />

      <UnexpectedBooking />

      <SelectStatus value={status} handleChangeFunction={handleChangeStatus} />

      <Loadable loading={isFetching}>
        <GridDX
          rows={bookings}
          columns={[
            {
              name: 'patient',
              title: t('bookings:bookings.list.table.patient'),
              getCellValue: (booking) => `${booking.patient.user.fullname}`
            },
            {
              name: 'date',
              title: t('bookings:bookings.list.table.date'),
              getCellValue: (booking) => {
                return `${
                  booking.patient.is_eligible
                    ? t('common:common.date.detailed', {
                        date: parseISO(booking.date)
                      })
                    : 'Non éligible'
                }`;
              }
            },
            {
              name: 'hour',
              title: t('bookings:bookings.list.table.time'),
              getCellValue: (booking) => {
                return `${
                  booking.patient.is_eligible
                    ? t('common:common.date.time', {
                        date: parseISO(booking.date)
                      })
                    : 'Non éligible'
                }`;
              }
            },
            {
              name: 'consultation',
              title: t('bookings:bookings.list.table.type'),
              getCellValue: (booking) =>
                t(`bookings:bookings.type.${booking.type}`)
            },
            {
              name: 'price',
              title: t('bookings:bookings.list.table.price'),
              getCellValue: (booking) => booking.formatted_price
            }
          ]}
        >
          <SearchState defaultValue="" />
          <IntegratedFiltering />
          <CodeTypeProvider for={codeColumns} />
          <Table columnExtensions={['code']} />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel />
        </GridDX>
      </Loadable>
    </Paper>
  );
};

export default ListBookings;
