import React from 'react';
import { Typography } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 24
  },
  labelsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  marksContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  mark: {
    margin: 5
  },
  highlightedMark: {
    margin: 5,
    fontSize: 22
  }
});

const Rating = ({ value, labels, classes }) => {
  const marks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className={classes.container}>
      <div className={classes.labelsContainer}>
        {labels.map((l) => (
          <Typography key={l}>{l}</Typography>
        ))}
      </div>
      <div className={classes.marksContainer}>
        {marks.map((m) => (
          <Typography
            key={m}
            component="span"
            color={m === value ? 'secondary' : 'textSecondary'}
            className={m === value ? classes.highlightedMark : classes.mark}
          >
            {m}
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(Rating);
