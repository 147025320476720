import { Paper } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import ListInvoices from './ListInvoices';

const Invoices = () => {
  const { t } = useTranslation('invoices');

  return (
    <Paper>
      <Header
        breadcrumbs={[
          {
            name: t('invoices:invoices.list.title'),
            url: '/invoices'
          }
        ]}
      />
      <ListInvoices />
    </Paper>
  );
};
export default Invoices;
