import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../final-form/Checkbox';
import TextField from '../../final-form/TextField';

import { consultationQuestions } from '../../../questions';

const Questions = ({ values, booking }) => {
  const { t, i18n } = useTranslation('bookings');
  return (
    <Grid container spacing={2}>
      {consultationQuestions.map((question) => {
        const isPremolarQuestion = question.name === 'premolars';
        return (
          <Fragment key={question.name}>
            <Grid item xs={12} sm={isPremolarQuestion ? 12 : 6}>
              <Field
                disabled={booking.status === 'done'}
                type="checkbox"
                name={question.name}
                label={question[i18n.language]}
                component={Checkbox}
                labelProps={
                  isPremolarQuestion && { style: { fontWeight: 'bold' } }
                }
              />
            </Grid>
            {isPremolarQuestion && !!values.premolars && (
              <Grid item xs={12}>
                <Field
                  name="premolars_details"
                  label={t(
                    'bookings:bookings.viewBooking.questions.premolarsDetails'
                  )}
                  disabled={booking.status === 'done'}
                  component={TextField}
                  fullWidth
                  multiline
                  required
                  // variant="outlined"
                />
              </Grid>
            )}
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default Questions;
